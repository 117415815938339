import { FormErrors } from 'redux-form';
import { clearEmptyFields } from 'logic/validators/utils';
import {
  isBicValidator,
  IsEuroAlpha,
  IsEuroAlphanumeric,
  IsEuroAlphanumericExtended,
  isIbanValidator,
  isPostcodeValidator,
  isRequiredValidator,
} from 'logic/validators/validators';

export const UPDATE_PAYOUT_INFO_FORM_NAME = 'update-payout-info';

export interface UpdatePayoutInfoFormValues {
  accountHolder?: string;
  iban?: string;
  bic?: string;
  smallBusinessRegulation?: boolean;
  taxNumber?: string;
  street?: string;
  houseNumber?: string;
  postcode?: string;
  city?: string;
  country?: string;
}

export type UpdatePayoutInfoFormValidationErrors = FormErrors<UpdatePayoutInfoFormValues, any>;

export const validateUpdatePayoutInfo = (formValues: UpdatePayoutInfoFormValues) => {
  const errors: UpdatePayoutInfoFormValidationErrors = {
    accountHolder:
      isRequiredValidator(formValues.accountHolder) || IsEuroAlphanumeric(formValues.accountHolder),
    iban: isRequiredValidator(formValues.iban) || isIbanValidator(formValues.iban),
    bic: isRequiredValidator(formValues.bic) || isBicValidator(formValues.bic),
    taxNumber:
      isRequiredValidator(formValues.taxNumber) || IsEuroAlphanumericExtended(formValues.taxNumber),
    street: isRequiredValidator(formValues.street) || IsEuroAlphanumericExtended(formValues.street),
    houseNumber:
      isRequiredValidator(formValues.houseNumber) ||
      IsEuroAlphanumericExtended(formValues.houseNumber),
    postcode:
      isRequiredValidator(formValues.postcode) ||
      isPostcodeValidator(formValues.postcode) ||
      IsEuroAlphanumeric(formValues.postcode),
    city: isRequiredValidator(formValues.city) || IsEuroAlphanumeric(formValues.city),
    country: isRequiredValidator(formValues.country) || IsEuroAlpha(formValues.country),
  };

  return clearEmptyFields(errors);
};
