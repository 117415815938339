import { firebaseAnalytics } from './google-analytics';
import { fbPixel } from './fb-pixel';

export enum AnalyticsAuthEvent {
  SignUp = 'sign_up',
  Login = 'login',
}

export enum AnalyticsEvent {
  SeekerHasRequestedTutor = 'tutor_requested',
  SeekerHasViewOffer = 'view_offer',
  TutorHasStartedPublishOffer = 'publish_offer_start',
  TutorHasPublishedOffer = 'publish_offer_success',
  TutorHasAcceptedSeekerRequest = 'request_accepted',
  TutorHasDeclinedSeekerRequest = 'request_declined',
  SeekerHasOpenedTutorsContactDetails = 'seeker_contactdata_viewed',
  ApplyAsEliteStart = 'applied_as_elite_start',
  ApplyAsEliteSuccess = 'applied_as_elite_success',
}

export enum PixelEvents {
  CompleteRegistration = 'CompleteRegistration',
  SubmitApplication = 'SubmitApplication',
  ViewContent = 'ViewContent',
  CustomizeProduct = 'CustomizeProduct',
  InitiateCheckout = 'InitiateCheckout',
  Contact = 'Contact',
  Lead = 'Lead',
  Purchase = 'Purchase',
}

export const triggerAnalyticsAuthEvent = (
  event: AnalyticsAuthEvent,
  userId?: string,
  method?: 'email' | 'facebook' | 'apple'
) => {
  if (firebaseAnalytics)
    switch (event) {
      case AnalyticsAuthEvent.SignUp:
        setFirebaseUserId(userId);
        firebaseAnalytics.logEvent(event, { method });
        return;
      case AnalyticsAuthEvent.Login:
        setFirebaseUserId(userId);
        firebaseAnalytics.logEvent(event, { method });
        return;
      default:
        return;
    }
};

export const triggerAnalyticsEvent = (event: AnalyticsEvent) => {
  firebaseAnalytics && firebaseAnalytics.logEvent(event);
};

export const triggerFBPixelEvent = (event: PixelEvents, parameters?: object) => {
  if (parameters) {
    fbPixel && fbPixel('track', event, parameters);
  } else {
    fbPixel && fbPixel('track', event);
  }
};

export const setFirebaseUserId = (userId: string | undefined) => {
  firebaseAnalytics && userId && firebaseAnalytics.setUserId(userId);
};
