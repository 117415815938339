import React, { CSSProperties, FC } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import tw from 'twin.macro';
import { translate, translationKeys } from 'logic/translations/translations.service';
import { scrollToId } from 'components/scroll-to-top/ScrollToTop';
import { navigationSlice } from 'logic/store/navigation/navigation.slice';
import { AppRoute } from 'app/app.route.const';
import { useDispatch, useSelector } from 'react-redux';
import { colors } from 'styles/colors';
import { useBreakpoints } from 'logic/hooks/use-breakpoints';
import { getIsAuthenticated } from 'logic/store/authentication/authentication.selectors';

type Props = {
  onClick?: (e?: React.MouseEvent<HTMLElement>) => void;
  style?: CSSProperties;
  disabled?: boolean;
};

const ButtonStyled = styled(Button)`
  ${tw`flex items-center content-center justify-center flex-1`}
  -webkit-box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.38);
  box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.38);
  max-width: 480px;
  height: auto;
  white-space: normal;
`;

const ButtonSection = styled.section`
  background-color: ${colors.catskillWhite};
  padding: 20px 0 40px 0;
  display: flex;
  justify-content: center;
`;

export const ActionButton: FC<Props> = ({ children, onClick, style, disabled }) => (
  <ButtonStyled
    type={'primary'}
    className={'form-button'}
    htmlType="button"
    style={style}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </ButtonStyled>
);

const PremiumButtonBox = styled.div`
  max-width: 500px;
`;

// TODO: delete this component and use HomepageButton instead (?)
export const EndActionButton: FC<{
  variant?: 'online-nachhilfe';
  tutorspacePlus?: boolean;
  tutorspacePremium?: boolean;
}> = ({ variant, tutorspacePlus, tutorspacePremium }) => {
  return (
    <ButtonSection id={'ende-button'}>
      <HomepageButtonLegacy
        variant={variant}
        tutorspacePremium={tutorspacePremium}
        tutorspacePlus={tutorspacePlus}
        center
      />
    </ButtonSection>
  );
};

// TODO: delete this component and use HomepageButton instead
export const HomepageButtonLegacy: FC<{
  variant?: 'online-nachhilfe';
  tutorspacePlus?: boolean;
  tutorspacePremium?: boolean;
  center?: boolean;
}> = ({ variant, tutorspacePlus, tutorspacePremium, center }) => {
  const dispatch = useDispatch();
  const { maxMobileLarge } = useBreakpoints();

  return (
    <PremiumButtonBox style={{ margin: center ? '0 auto' : '' }}>
      <ActionButton
        style={{
          margin: maxMobileLarge ? '24px 0 40px 0' : '24px auto 40px auto',
          fontWeight: 700,
          width: maxMobileLarge ? '100%' : '300px',
        }}
        onClick={() =>
          tutorspacePlus
            ? scrollToId('#main-search')
            : tutorspacePremium
            ? dispatch(
                navigationSlice.actions.navigateTo({
                  path:
                    variant === 'online-nachhilfe'
                      ? AppRoute.External_TrialHour_OnlineTutoring
                      : AppRoute.External_TrialHour,
                })
              )
            : dispatch(navigationSlice.actions.navigateTo({ path: AppRoute.Home }))
        }
      >
        {tutorspacePlus || tutorspacePremium
          ? translate(translationKeys.homepage.howWeChooseElitetutors.buttonTutorspacePlus)
          : translate(translationKeys.homepage.howWeChooseElitetutors.button)}
      </ActionButton>
    </PremiumButtonBox>
  );
};

export const HomepageButton: FC<{
  text: string;
  onClick: () => void;
  style?: CSSProperties;
  center?: boolean;
}> = ({ text, onClick, style, center }) => {
  const { maxMobileLarge } = useBreakpoints();

  return (
    <PremiumButtonBox style={{ margin: center ? '0 auto' : '' }}>
      <ActionButton
        style={{
          fontWeight: 700,
          width: maxMobileLarge ? '100%' : '320px',
          ...style,
        }}
        onClick={onClick}
      >
        {text}
      </ActionButton>
    </PremiumButtonBox>
  );
};

export const PremiumTutorApplyButton: FC<{ style?: CSSProperties; center?: boolean }> = ({
  style,
  center,
}) => {
  const dispatch = useDispatch();
  const { maxMobileLarge } = useBreakpoints();
  const isAuthenticated = useSelector(getIsAuthenticated);

  return (
    <ActionButton
      onClick={() =>
        dispatch(
          navigationSlice.actions.navigateTo({
            path: isAuthenticated
              ? AppRoute.EliteTutorOffer
              : AppRoute.TutorspacePremiumRegisterAsTutor,
          })
        )
      }
      style={{
        fontWeight: 700,
        width: maxMobileLarge ? '100%' : '320px',
        margin: center ? '0 auto' : '',
        ...style,
      }}
    >
      {translate(translationKeys.becomePremiumTutor.button)}
    </ActionButton>
  );
};
