import styled from 'styled-components';
import { Image } from 'antd';
import tw from 'twin.macro';

export const ImageFit = styled(Image)`
  ${tw`flex items-center content-center`}
  img {
    height: 320px;
    object-fit: cover;
    max-width: 768px;
    width: 100%;
    border-radius: 10px;
  }
`;

export const ImageFitContain = styled(ImageFit)`
  background-color: #f5f8fa;

  img {
    object-fit: contain;
    margin: auto;
    border-radius: 0;
  }
`;

export const VideoFit = styled.video`
  ${tw`flex items-center content-center`}
  video {
    height: 320px;
    object-fit: cover;
    max-width: 768px;
    width: 100%;
    border-radius: 10px;
  }
`;

export const VideoFitContain = styled(VideoFit)`
  background-color: #f5f8fa;

  video {
    object-fit: contain;
    margin: auto;
    border-radius: 0;
  }
`;
