import { AppRoute } from 'app/app.route.const';
import { TutoringKind } from 'components/search/search.const';
import { OfferRequestStatus } from 'logic/api-models/offer-request-status';
import { OfferRequestType } from 'logic/api-models/offer-request-type';
import {
  TeachingLevel,
  TutoringRequestTeachingLevel,
  TutoringRequestTeachingLevelV2,
} from 'logic/api-models/teaching-level';
import { TutoringType } from 'logic/api-models/tutoring-type';
import { ReportOfferReason } from 'logic/api-models/report-offer';
import { GroupSize, WeekDay } from 'logic/api-models/api-models';
import { TimeOfDay } from 'logic/api-models/time-of-day';
import { SubscriptionInterval } from 'logic/subscription-interval/subscription-interval';
import { SortOffersBy } from 'logic/api-models/sort-offer-by';

export const ua = {
  common: {
    costAndRate: undefined,
    voluntary: 'Волонтерство',
    costSchool: undefined,
    pagination: {
      start: undefined,
      search: {
        schools: undefined,
        online: undefined,
        localTutors: undefined,
      },
    },

    teachingLevel: {
      [TeachingLevel.klasse4]: undefined,
      [TeachingLevel.klasse7]: undefined,
      [TeachingLevel.klasse10]: undefined,
      [TeachingLevel.abitur]: undefined,
      [TeachingLevel.berufsschule]: undefined,
      [TeachingLevel.fachhochschule]: undefined,
      [TeachingLevel.universtitatStudium]: undefined,
      unknown: undefined,
    },
    requestTeachingLevel: {
      [TutoringRequestTeachingLevel.CLASS1]: undefined,
      [TutoringRequestTeachingLevel.CLASS2]: undefined,
      [TutoringRequestTeachingLevel.CLASS3]: undefined,
      [TutoringRequestTeachingLevel.CLASS4]: undefined,
      [TutoringRequestTeachingLevel.CLASS5]: undefined,
      [TutoringRequestTeachingLevel.CLASS6]: undefined,
      [TutoringRequestTeachingLevel.CLASS7]: undefined,
      [TutoringRequestTeachingLevel.CLASS8]: undefined,
      [TutoringRequestTeachingLevel.CLASS9]: undefined,
      [TutoringRequestTeachingLevel.CLASS10]: undefined,
      [TutoringRequestTeachingLevel.CLASS11]: undefined,
      [TutoringRequestTeachingLevel.CLASS12]: undefined,
      [TutoringRequestTeachingLevel.ABITUR]: undefined,
      [TutoringRequestTeachingLevel.UNIVERSITY]: undefined,
      unknown: undefined,
    },
    requestTeachingLevelV2: {
      [TutoringRequestTeachingLevelV2.CLASS1]: undefined,
      [TutoringRequestTeachingLevelV2.CLASS2]: undefined,
      [TutoringRequestTeachingLevelV2.CLASS3]: undefined,
      [TutoringRequestTeachingLevelV2.CLASS4]: undefined,
      [TutoringRequestTeachingLevelV2.CLASS5]: undefined,
      [TutoringRequestTeachingLevelV2.CLASS6]: undefined,
      [TutoringRequestTeachingLevelV2.CLASS7]: undefined,
      [TutoringRequestTeachingLevelV2.CLASS8]: undefined,
      [TutoringRequestTeachingLevelV2.CLASS9]: undefined,
      [TutoringRequestTeachingLevelV2.CLASS10]: undefined,
      [TutoringRequestTeachingLevelV2.CLASS11]: undefined,
      [TutoringRequestTeachingLevelV2.CLASS12]: undefined,
      [TutoringRequestTeachingLevelV2.ABITUR]: undefined,
      unknown: undefined,
    },
    teachingLocation: {
      [TutoringType.atTeacher]: 'У вчителя вдома',
      [TutoringType.atStudent]: 'У студента вдома',
      [TutoringType.online]: 'Онлайн',
    },
    instituteTeachingLocation: {
      [TutoringType.atTeacher]: undefined,
      [TutoringType.atStudent]: undefined,
      [TutoringType.online]: undefined,
    },
    groupSize: {
      [GroupSize.GROUP]: undefined,
      [GroupSize.SINGLE]: undefined,
    },
    weekDay: {
      [WeekDay.MONDAY]: undefined,
      [WeekDay.TUESDAY]: undefined,
      [WeekDay.WEDNESDAY]: undefined,
      [WeekDay.THURSDAY]: undefined,
      [WeekDay.FRIDAY]: undefined,
      [WeekDay.SATURDAY]: undefined,
      [WeekDay.SUNDAY]: undefined,
    },
    timeOfDay: {
      [TimeOfDay.Morning]: undefined,
      [TimeOfDay.Evening]: undefined,
    },
    openingHours: {
      openAt: undefined,
      closeAt: undefined,
      closed: undefined,
    },
    otherPeopleSearchFor: {
      leadText: undefined,
      onSiteTeacher: undefined,
      onSiteSchool: undefined,
      onlineTeacher: undefined,
    },
    dataProtection: undefined,
    here: undefined,
    legal: {
      companyName: undefined,
      name: undefined,
      operator: undefined,
      management: undefined,
      representative: undefined,
      supportEmail: undefined,
      address: undefined,
      legalAddress: undefined,
      headquarters: undefined,
      phone: undefined,
      infoEmail: undefined,
      website: undefined,
      taxID: undefined,
      onlineDisputeReference: undefined,
      onlineImageSources: undefined,
    },
    ad: undefined,
  },
  partner: {
    pros: {
      first: {
        title: undefined,
        subTitle: undefined,
      },
      second: {
        title: undefined,
        subTitle: undefined,
      },
      last: {
        title: undefined,
        subTitle: undefined,
      },
    },
    button: undefined,
    buttonText: undefined,
    buttonSubText: undefined,
  },
  aboutus: {
    typesTitle: undefined,
    type: {
      first: { title: undefined, subTitle: undefined },
      second: { title: undefined, subTitle: undefined },
      last: { title: undefined, subTitle: undefined },
    },
    typesButton: undefined,
    prosTitle: undefined,
    pros: {
      first: {
        title: undefined,
        subTitle: undefined,
      },
      second: {
        title: undefined,
        subTitle: undefined,
      },
      last: {
        title: undefined,
        subTitle: undefined,
      },
    },
    typeTitle: undefined,
    typeCommunity: {
      first: {
        title: undefined,
        subTitle: undefined,
      },
      second: {
        title: undefined,
        subTitle: undefined,
      },
      third: {
        title: undefined,
        subTitle: undefined,
      },
      fourth: {
        title: undefined,
        subTitle: undefined,
      },
      last: {
        title: undefined,
        subTitle: undefined,
      },
    },
    tiersTitle: undefined,
    tiersSubTitle: undefined,
    tiersSubTitleContent: undefined,
    tiersSubTitle2: undefined,
    tiersSubTitle2Content: undefined,
    CTATitle: undefined,
    CTASubTitle: undefined,
  },
  faq: {
    title: undefined,
    subTitle: undefined,
    subItems: {
      marketplace: undefined,
      elite: undefined,
    },
    types: {
      first: {
        title: undefined,
      },
      second: {
        title: undefined,
      },
      last: {
        title: undefined,
      },
    },
    CTA: {
      title: undefined,
      subTitle1: undefined,
      subTitle2: undefined,
      subTitle3: undefined,
      button: undefined,
    },
  },
  rateTooltips: {
    titleSchool: undefined,
    rateSchool: undefined,
    needStar: undefined,
    thankYouInput: undefined,
    didNotTakePlace: undefined,
    rate: undefined,
    wait20Days: undefined,
    wait20DaysLong: undefined,
    thankYouText: undefined,
    errorText: undefined,
    doneButton: undefined,
    starsTitle: undefined,
    reasonTitle: undefined,
    successButton: undefined,
    buttonSend: undefined,
    buttonSend2: undefined,
    leadText: undefined,
    problemText: undefined,
    helperText: undefined,
    placeholder: undefined,
    placeholder2: undefined,
    0: undefined,
    1: undefined,
    2: undefined,
    3: undefined,
    4: undefined,
    5: undefined,
  },
  homepage: {
    quotations: {
      heading: undefined,
      buttonOnline: undefined,
      buttonHomepage: undefined,
      buttonTutorspacePlus: undefined,
      quote1: {
        text: undefined,
        author: undefined,
        authorRole: undefined,
      },
      quote2: {
        text: undefined,
        author: undefined,
        authorRole: undefined,
      },
      quote3: {
        text: undefined,
        author: undefined,
        authorRole: undefined,
      },
      quote4: {
        text: undefined,
        author: undefined,
        authorRole: undefined,
      },
      quote5: {
        text: undefined,
        author: undefined,
        authorRole: undefined,
      },
      quote6: {
        text: undefined,
        author: undefined,
        authorRole: undefined,
      },
      quote7: {
        text: undefined,
        author: undefined,
        authorRole: undefined,
      },
      quote8: {
        text: undefined,
        author: undefined,
        authorRole: undefined,
      },
    },
    features: {
      heading: undefined,
      feature1: {
        heading: undefined,
        paragraph: undefined,
      },
      feature2: {
        heading: undefined,
        paragraph: undefined,
      },
      feature3: {
        heading: undefined,
        paragraph: undefined,
      },
      feature4: {
        heading: undefined,
        paragraph: undefined,
      },
    },
    createOfferStrip: {
      leadText: undefined,
      createButton: undefined,
      privateFreeHeading: undefined,
      trialLesson: undefined,
      privateFreeDescription: undefined,
      cost: undefined,
    },
    findingTutors: {
      subheading: {
        item1: {
          title: undefined,
          description: undefined,
        },
        item2: {
          title: undefined,
          description: undefined,
        },
      },
      onSiteTutoring: {
        heading: undefined,
        step1: {
          heading: undefined,
          paragraph: undefined,
        },
        step2: {
          heading: undefined,
          paragraph: undefined,
        },
        step3: {
          heading: undefined,
          paragraph: undefined,
        },
        compareButton: undefined,
        info: {
          title: undefined,
          text1: undefined,
          text2: undefined,
        },
        profit: {
          headline: undefined,
          item1: {
            title: undefined,
            text: undefined,
          },
          item2: {
            title: undefined,
            text: undefined,
          },
          item3: {
            title: undefined,
            text: undefined,
          },
        },
      },
      onlineTutoring: {
        heading: undefined,
        step1: {
          heading: undefined,
          paragraph: undefined,
        },
        step2: {
          heading: undefined,
          paragraph: undefined,
        },
        step3: {
          heading: undefined,
          paragraph: undefined,
        },
        compareButton: undefined,
      },
      eliteTutoring: {
        step1: {
          heading: undefined,
          paragraph: undefined,
        },
        step2: {
          heading: undefined,
          paragraph: undefined,
        },
        step3: {
          heading: undefined,
          paragraph: undefined,
        },
        compareButton: undefined,
      },
      step1: {
        heading: undefined,
        paragraph: undefined,
      },
      step2: {
        heading: undefined,
        paragraph: undefined,
      },
      step3: {
        heading: undefined,
        paragraph: undefined,
      },
      compareButton: undefined,
    },
    tutorspaceInNumbers: {
      heading: undefined,
      item1: {
        title: undefined,
        description: undefined,
      },
      item2: {
        title: undefined,
        description: undefined,
      },
    },
    howDoesItWork: {
      students: {
        heading: undefined,
        step1: {
          heading: undefined,
          paragraph: undefined,
        },
        step2: {
          heading: undefined,
          paragraph: undefined,
        },
        step3: {
          heading: undefined,
          paragraph: undefined,
        },
        button: undefined,
      },
      tutors: {
        heading: undefined,
        step1: {
          heading: undefined,
          paragraph: undefined,
        },
        step2: {
          heading: undefined,
          paragraph: undefined,
        },
        step3: {
          heading: undefined,
          paragraph: undefined,
        },
        button: undefined,
      },
    },
    discoverEliteTutoring: {
      heading: undefined,
      plusHeading: undefined,
      description: undefined,
    },
    howToOnlineTutoring: {
      heading: undefined,
      feature1: undefined,
      feature2: undefined,
      button: undefined,
    },
    howElitetutoringWorks: {
      bulletpointbox1: {
        headline: undefined,
        content: [undefined, undefined, undefined, undefined, undefined],
        button: undefined,
        buttonTutorSpacePlus: undefined,
      },
      bulletpointbox2: {
        headline: undefined,
        content: [undefined, undefined, undefined, undefined],
        extraInfo: {
          item1: {
            headline: undefined,
            text: undefined,
          },
          item2: {
            headline: undefined,
            text: undefined,
          },
        },
        pricePreview: {
          perUnit: undefined,
          contractDuration: undefined,
          perMonth: undefined,
        },
      },
    },
    onlineTutoringAdvantageTiles: {
      tile1: {
        heading: undefined,
        description: undefined,
      },
      tile2: {
        heading: undefined,
        description: undefined,
      },
      tile3: {
        heading: undefined,
        description: undefined,
      },
      button: {
        default: undefined,
        tutorspacePlus: undefined,
      },
    },
    video: {
      title: undefined,
      description: undefined,
      descriptionExtra: undefined,
    },
    faq: {
      heading: undefined,
      contact: {
        info: undefined,
        reachability: undefined,
      },
    },
    compare: {
      heading: undefined,
      infoText: undefined,
      tutorspace: undefined,
      others: undefined,
      content: [
        {
          point: undefined,
          tutorspace: undefined,
          others: undefined,
        },
        {
          point: undefined,
          tutorspace: undefined,
          tutorspaceExtra: undefined,
          others: undefined,
        },
        {
          point: undefined,
          tutorspace: undefined,
          others: undefined,
        },
        {
          point: undefined,
          tutorspace: undefined,
          tutorspaceExtra: undefined,
          others: undefined,
        },
        {
          point: undefined,
          tutorspace: undefined,
          others: undefined,
        },
        {
          point: undefined,
          tutorspace: undefined,
          others: undefined,
        },
      ],
    },
    advantages: {
      heading: undefined,
      contents: {
        content1: {
          title: undefined,
          description: undefined,
        },
        content2: {
          title: undefined,
          description: undefined,
        },
        content3: {
          title: undefined,
          description: undefined,
        },
        content4: {
          title: undefined,
          description: undefined,
        },
        content5: {
          title: undefined,
          description: undefined,
        },
        content6: {
          title: undefined,
          description: undefined,
        },
        content7: {
          title: undefined,
          description: undefined,
        },
        content8: {
          title: undefined,
          description: undefined,
        },
        content9: {
          title: undefined,
          description: undefined,
        },
      },
    },
    reviews: {
      heading: undefined,
    },
    howWeChooseElitetutors: {
      heading: undefined,
      content: {
        bulletpoints_SEARCH_CARD: { regular1: undefined, bold: undefined, regular2: undefined },
        bulletpoints_SCHOOL_ICON: { regular1: undefined, bold: undefined, regular2: undefined },
        bulletpoints_GROUP_ICON: { regular1: undefined, bold: undefined, regular2: undefined },
        bulletpoints_SPEECH_ICON: { regular1: undefined, bold: undefined, regular2: undefined },
        bulletpoints_CHECK_ICON: { regular1: undefined, bold: undefined, regular2: undefined },
        promotion: undefined,
        promotionElite: undefined,
      },
      button: undefined,
      buttonTutorspacePlus: undefined,
      buttonHomePage: undefined,
    },
    seoBox: {
      item1: {
        title: undefined,
        content: undefined,
      },
      item2: {
        title: undefined,
        content: undefined,
      },
    },
    guideline: {
      headline: undefined,
      textBold: undefined,
      text: undefined,
      downloadButton: undefined,
    },
    endActionButton: {
      tutorspacePlus: undefined,
      onlineTutoring: undefined,
    },
    topPromotionBar: {
      textDesktop: undefined,
      textMobile: undefined,
    },
    trustpilotPromotionBar: {
      text: undefined,
      phone: undefined,
    },
    promotionBar: {
      new: undefined,
      text: undefined,
    },
    seo: {
      tutorspacePremium: {
        title: undefined,
        description: undefined,
      },
    },
    premium: {
      headingComparison: undefined,
      headingHomePage: undefined,
      bulletpoints: {
        bulletpoint1: undefined,
        bulletpoint2: undefined,
        bulletpoint3: undefined,
      },
      button: undefined,
      text: undefined,
    },
    refugeeHeader: {
      news: 'новини',
    },
    donationPromotion: {
      heading: undefined,
      infoBoxBadge: undefined,
      paragraphs: {
        paragraph1: undefined,
        paragraph2: undefined,
      },
    },
    refugeeInitiativePromotion: {
      heading: 'Разом допомагаємо біженцям вивчити німецьку мову та облаштуватись',
      paragraphs: {
        paragraph1:
          'Звісно, ​​ми також маємо надію, що всі біженці зможуть повернутися до свого звичного середовища якомога швидше. Але ми звичайно залишаємося реалістами і боїмося, що багатьом не вдасться повернутися додому в найближчому майбутньому. Саме тому нам дуже важливо дати їм можливість швидко та добре інтегруватися в Німеччині, і мова тут є вирішальним фактором.',
        paragraph2:
          'Тому ми робимо нашу платформу доступною для відповідних уроків безкоштовно. Звичайно, біженці з усіх країн дуже раді.',
      },
      infoBox: {
        text1: 'Безкоштовна платформа вчителів німецької мови',
        text2: 'Ми надаємо навчальні матеріали',
        text3: 'Координація з центрами для біженців',
        text4: 'Організація вчителів німецької мови',
      },
      button: 'Вивчайте більше',
    },
    refugeesBecomeTeacher: {
      heading: 'Підтримай біженців! Зареєструйся волонтером.',
      description:
        'Ти розмовляєш німецькою? Тоді ти теж можеш допомогти. З нашими спеціальними навчальними матеріалами тобі не потрібно бути вчителем, щоб викладати німецьку мову на елементарному рівні. Будь-яка допомога має значення!',
      button: 'Допомогти біженцям зараз',
    },
    firstAidKitGerman: {
      heading: 'Наша німецька аптечка',
      description:
        'Звичайно, можна швидко навчити біженців спілкуватися. Усі волонтери отримують навчальні матеріали з аудіофайлами та PDF-файлами з 10 найважливіших предметних областей.',
      list:
        'Німецький алфавіт і правила читання\n' +
        'Привітатися і попрощатися\n' +
        'Знайомство один з одним\n' +
        'Моя родина\n' +
        'Звертайтеся за допомогою та спілкуйтеся\n' +
        'Як справи? Здоров’я та благополуччя\n' +
        'Дякую, будь ласка і вибачте\n' +
        'Шопінг\n' +
        'Заповніть форми\n' +
        'На ходу',
      buttonDownloadUkrainian: 'Завантажити зараз (українською)',
      buttonDownloadRussian: 'Завантажити зараз (рос.)',
    },
    refugeesAboutUs: {
      heading: 'Хто ми є',
      description:
        'Ми – команда волонтерів, підприємців та експертів, які допомагають українським сім’ям біженців інтегруватися в Німеччини завдяки вивченню німецької мови. Організаторами ініціативи є TutorSpace та DeinSprachcoach.',
      people: [
        {
          name: 'Patrick Nadler',
          role: 'Організатор',
          description:
            'TutorSpace, голова правління Федеральної асоціації репетиторства та навчання у групах продовженого дня.',
          url: 'https://www.linkedin.com/in/patrick-nadler/',
        },
        {
          name: 'Maria Hasbolat',
          role: 'Організатор',
          description:
            'Засновник DeinSprachcoach щодня допомагає понад 1 000 000 шанувальникам опанувати німецьку мову - Instagram, TikTok та YouTube',
          url: 'https://www.linkedin.com/in/maria-hasbolat-982307122/',
        },
        {
          name: 'Dr. Christoph Jaschinski',
          role: 'Організатор',
          description:
            'Засновник та уповноважений співробітник TutorSpace, лікар, науковець та підприємець - вивчав медицину, біохімію та право',
          url:
            'https://www.linkedin.com/in/dr-med-christoph-h-h-jaschinski-mag-iur-heidelberg-a0b47872/',
        },
        {
          name: 'Dr. Felix M. Michl',
          role: 'Організатор',
          description:
            'Засновник та уповноважений підписант TutorSpace, юрист; раніше професор права та керуючий директор стартапу "SCRAEGG"',
          url: 'https://www.linkedin.com/in/dr-felix-m-michl-bb711933/',
        },
      ],
    },
    refugeesOurPartners: {
      heading: 'Наші партнери',
    },
    refugeesWeNeedHelp: {
      heading: 'Нам потрібна твоя допомога!',
      description:
        'Якщо ти вважаєш, що можеш допомогти нам із пошуком кадрів, наставництвом, комунікацією або фінансово, будь ласка, повідом нам про це.',
      button: 'Контактувати зараз',
    },
    knownFrom: {
      headline: undefined,
    },
    pressContact: {
      button: undefined,
    },
  },
  userProfile: {
    heading: undefined,
    officialName: undefined,
    updateUserNameForm: {
      description: undefined,
    },
    email: undefined,
    updateEmailForm: {
      description: undefined,
      field: undefined,
    },
    messages: {
      yourConversations: undefined,
      noMessages: undefined,
      noResultsCTAHeader: undefined,
      noResultsCTAButton: undefined,
    },
    deleteAccount: {
      button: undefined,
      confirmation: {
        title1: undefined,
        title2: undefined,
        description1: undefined,
        description2: undefined,
        cancelButton: undefined,
        okButton: undefined,
      },
      notification: undefined,
    },
    deleteOffer: {
      deleteOfferCTA: undefined,
      modalTitle1: undefined,
      modalTitle2: undefined,
      modalText1: undefined,
      modalText2: undefined,
      backToProfile: undefined,
      confirmDeletion: undefined,
    },
    updatePhoneForm: {
      fields: {
        newPhoneNumber: undefined,
        code: undefined,
      },
      updatingPhoneDescription: undefined,
      codeDescription: undefined,
      sendCodeAgainButton: undefined,
    },
  },
  tutoringInstituteProfile: {
    onMobile: {
      backToHomepage: undefined,
      text1: undefined,
      text2: undefined,
    },
    yourCooperation: undefined,
    subscriptions: {
      monthly: undefined,
      PPLMonthly: undefined,
      placeSingle: undefined,
      placeSmallRange: undefined,
      placeLargeRange: undefined,
      price: undefined,
      interval: {
        [SubscriptionInterval.Month]: undefined,
      },
      perPlace: undefined,
      features: {
        title: undefined,
        items1: undefined,
        items2: undefined,
        items3: undefined,
        items4: undefined,
      },
      subscriptionUsage: {
        noActiveSubscriptions: undefined,
        first: undefined,
        second: undefined,
        third: undefined,
        PPL: undefined,
      },
      provideBillingInfo: undefined,
      payDueInvoices: undefined,
      payDueInvoicesFromPreviousSubscription: undefined,
      publishLocationToStartSubscription: undefined,
      subscriptionNextPaymentIs: undefined,
      subscriptionNextPaymentIsAutomatic: undefined,
      PPLNextPaymentIs: undefined,
      discountLabel: undefined,
      cancelSubscription: {
        button: undefined,
        confirmation: {
          title1: undefined,
          title2: undefined,
          cancelButton: undefined,
          okButton: undefined,
        },
        notification: undefined,
        cancelBanner: undefined,
      },
    },
    paymentMethods: undefined,
    invoices: {
      title: undefined,
      subscriptionChangesNote: undefined,
      columnTitle: {
        productName: undefined,
        number: undefined,
        date: undefined,
        price: undefined,
        status: undefined,
        download: undefined,
      },
      status: {
        pay: undefined,
        paid: undefined,
      },
    },
    cardExpiresAt: undefined,
    payByInvoice: undefined,
    standardPaymentMethod: undefined,
    setAsStandardMethod: undefined,
    removePaymentMethod: undefined,
    offersPage: {
      statistics: undefined,
      yourOffers: undefined,
      offersOnline: undefined,
      reviews: undefined,
      totalOffersAppearedOnSearch: undefined,
      totalOffersDetailsDisplayed: undefined,
      addOffer: undefined,
      active: undefined,
      inactive: undefined,
      warningOffersAreInactive: undefined,
      warningOffersAreInactiveTooltip: undefined,
      deleteOffer: {
        modalTitle1: undefined,
        modalTitle2: undefined,
        modalText1: undefined,
        modalText2: undefined,
        back: undefined,
        confirmDeletion: undefined,
      },
      offerTable: {
        offerName: undefined,
        location: undefined,
        street: undefined,
        rating: undefined,
        foundInSearch: undefined,
        visitor: undefined,
        status: undefined,
        edit: undefined,
        delete: undefined,
      },
    },
    paymentsPage: {
      addPaymentMethodCTA: undefined,
      editBillingInfoCTA: undefined,
      billingInfoForm: {
        header: undefined,
        warning: undefined,
        name: undefined,
        email: undefined,
        address1: undefined,
        country: undefined,
        city: undefined,
        zip: undefined,
        vat: undefined,
        vatPlaceholder: undefined,
        vatId: undefined,
        confirmButton: undefined,
      },
      addMethodForm: {
        header: undefined,
        creditCardData: undefined,
        invoice: {
          header1: undefined,
          infoDisclaimer: undefined,
          description1: undefined,
          description2: undefined,
        },
        savePaymentMethodCTA: undefined,
        sepa: {
          accountNumber: undefined,
          accountHolder: undefined,
          legal: undefined,
        },
        paymentMethod: {
          card: undefined,
          SEPA: undefined,
          invoice: undefined,
        },
      },
      confirmPaymentStartModal: {
        title: undefined,
        content: undefined,
        confirmCTA: undefined,
        cancelCTA: undefined,
      },
      lastPayment: undefined,
      noPaymentYet: undefined,
      billingInfoIsNotProvided: undefined,
    },
  },
  tutorDetails: {
    info: {
      age: 'років',
      impressum: undefined,
      subjectsOffered: 'Предмети',
      reviews: undefined,
      elitetutor: undefined,
      elitetutorExtraInfo: undefined,
    },
    approximateLocationMap: {
      title: 'Приблизне місце розміщення оголошення',
    },
  },
  publishOffer: {
    emptyState: {
      CTAHeader: undefined,
      CTAButton: undefined,
    },
    pageHeaderText: undefined,
    backButton: undefined,
    subjects: {
      heading: undefined,
      inputLabel: undefined,
      placeholder: undefined,
    },
    description: {
      heading: undefined,
      paragraph: undefined,
      paragraph2: undefined,
      note: undefined,
    },
    descriptionInstruction: {
      heading: undefined,
      paragraphs: {
        paragraph1: undefined,
        paragraph2: undefined,
        paragraph3: undefined,
        paragraph4: undefined,
        paragraph5: undefined,
      },
    },
    location: {
      heading: undefined,
      paragraph: undefined,
      landRegion: {
        label: undefined,
        placeholder: undefined,
      },
      street: {
        label: undefined,
        placeholder: undefined,
      },
      city: {
        label: undefined,
        placeholder: undefined,
      },
      postcode: {
        label: undefined,
        placeholder: undefined,
      },
    },
    video: {
      heading: undefined,
      paragraph: undefined,
      uploadVideo: undefined,
      dragVideo: undefined,
      uploadNewVideo: undefined,
      deleteVideo: undefined,
      deleteVideoWarning: undefined,
      approvalInfo: {
        heading: undefined,
        text: undefined,
      },
    },
    photo: {
      heading: undefined,
      paragraph: undefined,
      uploadPhoto: undefined,
      dragPhoto: undefined,
      uploadNewPicture: undefined,
      uploadPhotos: undefined,
      uploadPhotoMaxSizeDescription: undefined,
      defaultPhoto: undefined,
    },
    photoInstruction: {
      heading: undefined,
      paragraphs: {
        paragraph1: undefined,
        paragraph2: undefined,
        paragraph3: undefined,
      },
    },
    videoInstruction: {
      heading: undefined,
      paragraphs: {
        paragraph1: undefined,
        paragraph2: undefined,
        paragraph3: undefined,
        paragraph4: undefined,
      },
    },
    price: {
      heading: undefined,
      paragraph1: undefined,
      paragraph2: undefined,
      label: undefined,
    },
    priceInstruction: {
      heading: undefined,
      paragraphs: {
        paragraph1: undefined,
        paragraph2: undefined,
      },
    },
    level: {
      heading: undefined,
      paragraph1: undefined,
      paragraph2: undefined,
      label: undefined,
      placeholder: undefined,
    },
    place: {
      heading: undefined,
      paragraph: undefined,
    },
    age: {
      heading: undefined,
      paragraph: undefined,
      label: undefined,
      placeholder: undefined,
    },
    guide: {
      heading: undefined,
      example1: {
        heading: undefined,
        paragraph: undefined,
      },
      example2: {
        heading: undefined,
        paragraph: undefined,
      },
      example3: {
        heading: undefined,
        paragraph: undefined,
      },
    },
    publish: {
      heading: undefined,
      paragraph: undefined,
      publishButton: undefined,
      updateButton: undefined,
    },
    error: {
      unsupportedMediaType: {
        UNSUPPORTED_MEDIA_TYPE: undefined,
        MEDIA_INFO_INVALID: undefined,
        VIDEO_DURATION_LIMIT_EXCEEDED: undefined,
      },
    },
  },
  publishInstituteOffer: {
    name: {
      heading: undefined,
      paragraph: undefined,
      label: undefined,
      placeholder: undefined,
      instruction: {
        heading: undefined,
        paragraphs: {
          paragraph1: undefined,
          paragraph2: undefined,
        },
      },
    },
    photo: {
      heading: undefined,
      paragraph: undefined,
      instruction: {
        heading: undefined,
        paragraphs: {
          paragraph1: undefined,
          paragraph2: undefined,
          paragraph3: undefined,
        },
      },
    },
    location: {
      heading: undefined,
      paragraph: undefined,
    },
    price: {
      heading: undefined,
      paragraph: undefined,
      label: undefined,
    },
    level: {
      heading: undefined,
      paragraph: undefined,
      label: undefined,
    },
    subjects: {
      heading: undefined,
      inputLabel: undefined,
    },
    place: {
      heading: undefined,
      paragraph: undefined,
    },
    groupSizes: {
      heading: undefined,
      paragraph: undefined,
    },
    openingHours: {
      heading: undefined,
      paragraph: undefined,
    },
    contact: {
      heading: undefined,
      paragraph: undefined,
      primaryContact: {
        label: undefined,
        placeholder: undefined,
      },
      phoneNumber: {
        label: undefined,
        placeholder: undefined,
      },
      email: {
        label: undefined,
        placeholder: undefined,
      },
      websiteUrl: {
        label: undefined,
        placeholder: undefined,
      },
      impressumUrl: {
        label: undefined,
        placeholder: undefined,
      },
      impressumFile: {
        label: undefined,
      },
    },
    errors: {
      fillRequiredAndChooseDefaultImage: undefined,
    },
  },
  offerListCard: {
    title: undefined,
    titleRefugees: '{{firstName}} допомагає вивчити німецьку мову',
    subjectRefugees: 'Німецька мова для біженців',
  },
  offerDetails: {
    description: 'Про мене',
    teachingLevelUpTo: undefined,
    classLocation: 'Місце навчання',
    groupTypes: undefined,
    contact: undefined,
    contactPerson: undefined,
    phone: undefined,
    email: undefined,
    hours: undefined,
    notProvided: undefined,
  },
  reportOffer: {
    reportCTA: 'Повідомити про пропозицію',
    title1: undefined,
    title2: undefined,
    reasons: {
      [ReportOfferReason.NotTutoringOffer]: undefined,
      [ReportOfferReason.NotAvailable]: undefined,
      [ReportOfferReason.MultipleAccounts]: undefined,
      [ReportOfferReason.Offensive]: undefined,
    },
    buttonText: undefined,
  },
  offerPdf: {
    documentTitle: undefined,
    title: undefined,
    priceLabel: undefined,
    priceValue: undefined,
    phone: undefined,
    email: undefined,
    moreInformation1: undefined,
    moreInformation2: undefined,
    moreInformation3: undefined,
    ripOff: {
      tutoringInSubjects: undefined,
      moreInformation: undefined,
    },
  },
  ratings: {
    showMoreCTA: undefined,
    showAllReviewsCTApt1: undefined,
    showAllReviewsCTApt2: undefined,
    showMyRatingsCTA: undefined,
    badges: {
      expert: undefined,
      flexible: undefined,
      motivator: undefined,
      excellent: undefined,
      communication: undefined,
      fun: undefined,
    },
  },
  becomeATutorPage: {
    title: undefined,
    subTitle: undefined,
    tutorWeb: undefined,
    goodSide: undefined,
    opinions: undefined,
    register: undefined,
    faq: undefined,
    button: undefined,
  },
  searchResultsPage: {
    common: {
      titleNoInformation: undefined,
      recommendedEliteTutor: undefined,
      noResultsFound: undefined,
      itemList: {
        level: undefined,
        ratings: 'рейтинг',
        bookTrial: undefined,
        book: undefined,
        bookTrialInfo: undefined,
        bookInfo: undefined,
        eliteLevel: undefined,
        more: undefined,
        verifiedTutor: undefined,
      },
      infoSection: {
        title: undefined,
      },
      otherTutoringOptions: {
        local: {
          first: {
            title: undefined,
            subTitle: undefined,
            subTitleNoSubject: undefined,
          },
          second: {
            title: undefined,
            subTitle: undefined,
            subTitleNoSubject: undefined,
          },
        },
        school: {
          first: {
            title: undefined,
            subTitle: undefined,
            subTitleNoSubject: undefined,
          },
          second: {
            title: undefined,
            subTitle: undefined,
            subTitleNoSubject: undefined,
          },
        },
        premium: {
          title: undefined,
          subTitle: undefined,
          button: undefined,
        },
      },
    },
    schools: {
      title: undefined,
      paragraph1: undefined,
      paragraph2: undefined,
    },
    localTutors: {
      title: undefined,
      paragraph1: undefined,
      paragraph2: undefined,
    },
    onlineTutors: {
      title: undefined,
      paragraph1: undefined,
      paragraph2: undefined,
    },
    eliteTutors: {
      title: undefined,
      subtitle: undefined,
      paragraph1: undefined,
      paragraph2: undefined,
    },
    localTutorsPerCity: {
      title: undefined,
      titleNoSubject: undefined,
      paragraph1: undefined,
      paragraph2: undefined,
    },
    refugeeTutors: {
      titleWithCity: '%{count} приватні вчителів німецької мови для біженців у %{city}',
      titleOnline: '%{count} приватні онлайн-вчителі для біженців',
      paragraph1:
        '1) Відправити запит вчителю німецької мови (максимум 3 запити на день)\n' +
        '2) Вчитель німецької мови приймає запит\n' +
        '3) Обидві сторони обмінюються контактами\n' +
        '4) Ви можете зустрітися або навчатися разом онлайн',
    },
  },
  infoVideoBox: {
    onlineTutoringPromotion: {
      headline: undefined,
      buttonText: undefined,
    },
    onlineTutorSearchSectionPromotion: {
      headline: undefined,
      subheadline: undefined,
      buttonText: undefined,
    },
    onlineTutoringPromotionSchoolDetailsPage: {
      headline: undefined,
      subheadline: undefined,
      buttonText: undefined,
      infoText: undefined,
    },
    onlineTutoringPromotionDetailsPage: {
      headline: undefined,
      subheadline: undefined,
      buttonText: undefined,
      infoText: undefined,
    },
  },
  applyAsEliteTutor: {
    headerText: undefined,
    headline: undefined,
    infoText: undefined,
    requirements: {
      headline: undefined,
      infoText: undefined,
      requirementSelection: {
        moreThanSixHours: undefined,
        olderThan18: undefined,
        elitetutorTerms: undefined,
      },
    },
    guide: {
      heading: undefined,
      example1: {
        heading: undefined,
        paragraph: undefined,
      },
      example2: {
        heading: undefined,
        paragraph: undefined,
      },
      example3: {
        heading: undefined,
        paragraph: undefined,
      },
    },
    button: {
      apply: undefined,
      return: undefined,
    },
    success: {
      title: undefined,
      infoText: undefined,
      specificInfoText: undefined,
    },
    retry: {
      title: undefined,
      infoText: undefined,
      specificInfoText: undefined,
    },
    declined: {
      title: undefined,
      infoText: undefined,
    },
  },
  timeSlotPicker: {
    selectionError: undefined,
    updateAvailabilitySuccess: undefined,
    button: {
      save: undefined,
      disableStartRestriction: undefined,
    },
    vacancyReminder: {
      title: undefined,
      info1: undefined,
    },
  },
  footer: {
    getTheAppStrip: {
      heading: undefined,
      paragraph: undefined,
    },
    links: {
      imprint: undefined,
      termsOfUse: undefined,
      dataProtection: undefined,
    },
    subjects: {
      title: undefined,
    },
    forTutors: {
      title: undefined,
      becomeATutor: undefined,
    },
    forStudents: {
      title: undefined,
      teachersNearYou: undefined,
    },
    localTutoring: {
      title: undefined,
      subItems: {
        becomeATutor: undefined,
        compareFree: undefined,
        howItWorks: undefined,
      },
    },
    onlineTutoring: {
      title: undefined,
      extra: undefined,
      subItems: {
        bookAFreeTrialLesson: undefined,
        becomeAnEliteTutor: undefined,
      },
    },
    aboutTutorSpace: {
      title: undefined,
      subItems: {
        jobs: undefined,
        aboutUs: undefined,
        faq: undefined,
        donationInitiative: undefined,
        forTutoringInstitutes: undefined,
        blog: undefined,
        contact: undefined,
        press: undefined,
      },
    },
    supportAndHelp: {
      title: undefined,
      subItems: {
        youAskWeAnswer: undefined,
        contact: undefined,
        blog: undefined,
        faq: undefined,
      },
    },
    rewards: {
      bestTututors: {
        title: undefined,
      },
    },
    citys: {
      title: undefined,
      cityList: {
        0: undefined,
        1: undefined,
        2: undefined,
        3: undefined,
        4: undefined,
        5: undefined,
        6: undefined,
      },
    },
  },
  header: {
    title: undefined,
    menuItems: {
      search: {
        title: undefined,
        subMenu: {
          comparison: {
            description: undefined,
            title: undefined,
          },
          premium: {
            description: undefined,
            title: undefined,
          },
        },
      },
      offer: {
        title: undefined,
        subMenu: {
          private: {
            description: undefined,
            title: undefined,
          },
          premium: {
            description: undefined,
            title: undefined,
          },
        },
      },
      aboutUs: {
        title: undefined,
        subMenu: {
          aboutUs: {
            title: undefined,
          },
          jobs: {
            title: undefined,
          },
          donationInitiative: {
            title: undefined,
          },
          faq: {
            title: undefined,
          },
          press: {
            title: undefined,
          },
        },
      },
    },
  },
  premiumHeader: {
    title: undefined,
    menuItems: {
      teacher: undefined,
      pricing: undefined,
      rating: undefined,
    },
  },
  errors: {
    unknownError: undefined,
    internalServerError: undefined,
    incorrectCredentials: undefined,
    communicationError: undefined,
    error: undefined,
    someOfValuesAreIncorrect: undefined,
    unableToAuthenticateWithFacebook: undefined,
    tokenExpired: undefined,
    incorrectEmail: undefined,
    offerAlreadyReported: undefined,
    invalidVatNumberForSelectedCountry: undefined,
    contactSupport: undefined,
    missingValues: undefined,
    missingCity: 'Будь ласка, виберіть місце.',
    noBBBJoinURL: undefined,
    noBBBJoinURLClosed: undefined,
    noBBBJoinPermission: undefined,
    BBBRoomNotFound: undefined,
  },
  buttons: {
    login: 'Увійти',
    forgotPassword: undefined,
    myMessages: undefined,
    myOffer: undefined,
    myProfile: undefined,
    instituteProfile: undefined,
    instituteOffers: undefined,
    logout: undefined,
    register: 'Реєстрація',
    ok: undefined,
    next: undefined,
    becomeTutor: undefined,
    save: undefined,
    delete: undefined,
    continueWithFacebook: undefined,
    continueWithThisFacebookAccount: undefined,
    continumeWithApple: undefined,
    otherFormOfRegisteration: undefined,
    giveLesson: undefined,
    search: undefined,
    requestLessons: 'Запит контактних данних',
    editOffer: undefined,
    offerPdf: undefined,
    share: undefined,
    rejectOfferRequest: undefined,
    acceptOfferRequest: undefined,
    acceptOfferRequestSchool: undefined,
    showContactDetails: undefined,
    keepLooking: undefined,
    activate: undefined,
    deactivate: undefined,
    update: undefined,
    edit: undefined,
    abort: undefined,
    sendRequest: undefined,
    visitWebsite: undefined,
    verify: undefined,
    trialEliteSeeker: undefined,
    trialEliteSeekerShort: undefined,
    bookALesson: undefined,
    confirm: undefined,
  },
  validator: {
    form: {
      isRequired: undefined,
      generalFormatIsNotValid: undefined,
      symbolsNotValid: undefined,
      isDefaultPhotoRequired: undefined,
      emailFormatIsNotValid: undefined,
      commentFormatIsNotValid: undefined,
      passwordFormatIsNotValid: undefined,
      passwordsMustMatch: undefined,
      inputTooShort: undefined,
      inputTooLong: undefined,
      passwordTooShort: undefined,
      phoneNumberIsNotValid: undefined,
      phoneValidationCodeIsNotValid: undefined,
      fileSizeCannotBeMoreThan: undefined,
      valueMustBeSmallerThan: undefined,
      valueMustBeHigherThan: undefined,
      openingHoursOverlaps: undefined,
      textIsTooShort: undefined,
      textMustHaveAtLeastOneLetter: undefined,
      textMustHaveAtLeastOneNumber: undefined,
    },
  },
  messages: {
    or: undefined,
    accessTokenSuccessfullyRefreshed: undefined,
    notFound: undefined,
    offerNotFound: undefined,
  },
  confirmation: {
    welcomeBackToTutorSpace: undefined,
    welcomeToTheTutorSpaceCommunity: undefined,
    youHaveBeenSuccessfullyLoggedOut: undefined,
    yourPhoneNumberVerifiedSuccessfully: undefined,
    yourRequestForThisOfferWasSendSuccessfully: undefined,
    weHaveSentYouConfirmationEmail: undefined,
    userNameWasSuccessfullyUpdated: undefined,
    youHaveRejectedRequestOfferSuccessfully: undefined,
    youHaveAcceptedRequestOfferSuccessfully: undefined,
    weHaveSentYouEmailToResetPassword: undefined,
    yourPasswordHasBeenSuccessfullyUpdated: undefined,
    yourOfferWasSuccessfullyPublished: undefined,
    yourOfferWasSuccessfullyUpdated: undefined,
    yourUserTypeWasSuccessfullyUpdated: undefined,
    youHaveSuccessfullyReportedAnOffer: undefined,
    youHaveSuccessfullyRatedAnOffer: undefined,
    offerWasDeletedSuccessfully: undefined,
    userVideoWasDeletedSuccessfully: undefined,
  },
  fields: {
    phone: undefined,
    password: undefined,
    passwordConfirmation: undefined,
    newPassword: undefined,
    newPasswordConfirmation: undefined,
    phoneVerificationCode: undefined,
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    emailPlaceholder: undefined,
    cancel: undefined,
    accept: undefined,
  },
  search: {
    title: undefined,
    title1: undefined,
    title2: undefined,
    manImage: {
      title: undefined,
    },
    promotion: {
      offer: undefined,
    },
    tutoringKindTabsShort: {
      [TutoringKind.onSiteTutoring]: undefined,
      [TutoringKind.onlineTutoring]: undefined,
      [TutoringKind.tutoringSchools]: undefined,
    },
    tutoringKindTabs: {
      [TutoringKind.onSiteTutoring]: undefined,
      [TutoringKind.onlineTutoring]: undefined,
      [TutoringKind.tutoringSchools]: undefined,
    },
    fields: {
      inWhichSubjectDoYouNeedHelp: undefined,
      inWhichSubjectDoYouNeedHelpShort: undefined,
      where: undefined,
      requestTeachingLevel: undefined,
    },
    popular: undefined,
    sortDropdown: {
      sortWith: undefined,
      hint: undefined,
      options: {
        [SortOffersBy.Default]: undefined,
        [SortOffersBy.Rating]: undefined,
        [SortOffersBy.Distance]: undefined,
        [SortOffersBy.Newest]: undefined,
        [SortOffersBy.HighestPrice]: undefined,
        [SortOffersBy.LowestPrice]: undefined,
      },
    },
  },
  refugeeInitiativeSearch: {
    title: 'Знайди вчителя-волонтера німецької мови',
    subHeadline:
      'На нашій посередницькій платформі ми допомагаємо українським біженцям, які були змушені покинути батьківщину, знайти контакти вчителів-волонтерів німецької мови',
    tutoringKindTabsShort: {
      [TutoringKind.onSiteTutoring]: 'Місцеві',
      [TutoringKind.onlineTutoring]: 'Онлайн',
    },
    tutoringKindTabs: {
      [TutoringKind.onSiteTutoring]: 'Місцеві вчителі',
      [TutoringKind.onlineTutoring]: 'Онлайн вчителі',
    },
    fields: {
      where: 'Де: місто, селище або поштовий індекс',
    },
    searchButton: 'Пошук',
  },
  tutorSpacePlusSearch: {
    title: undefined,
    infoBox: {
      headline: undefined,
      bulletPoints: [undefined, undefined, undefined],
    },
    button: undefined,
  },
  donationInitiative: {
    donationHeader: {
      title: undefined,
      description: undefined,
    },
    banner: {
      text: undefined,
    },
    tiles: {
      tile1: {
        title: undefined,
        description: undefined,
      },
      tile2: {
        title: undefined,
        description: undefined,
      },
    },
    donationPartner: {
      title: undefined,
      description: undefined,
    },
  },
  knownFrom: {
    heading: undefined,
  },
  requestTutoringFlow: {
    privateRequest: undefined,
    inWhichSubjectDoYouNeedAHelp: undefined,
    whatSubjectPlaceholder: undefined,
    whatLocation: undefined,
    whatLevel: undefined,
    whatLevelPlaceholder: undefined,
    howManyHours: undefined,
    howManyHoursPlaceholder: undefined,
    howManyHoursDisclaimer: undefined,
  },
  forms: {
    login: {
      title: undefined,
    },
    forgotPassword: {
      title: undefined,
      messages: {
        enterEmail: undefined,
      },
      buttons: {
        submit: undefined,
      },
    },
    setPassword: {
      title: undefined,
      instruction: undefined,
    },
    registerWithEmail: {
      title: undefined,
      messages: {
        lastNameDescription: undefined,
        emailDescription: undefined,
        termsOfUseDescription1: undefined,
        termsOfUseDescription2: undefined,
        alreadyHaveAnAccount: undefined,
        notHaveAnAccount: undefined,
      },
      buttons: {
        termsOfUse: undefined,
      },
    },
    continueWithFacebookConfirmation: {
      title: undefined,
      buttons: {
        continuneWithFacebook: undefined,
      },
    },
    registerPhone: {
      title: undefined,
      messages: {
        weWillSendYouSmsValidationCode: undefined,
        whyWeValidatePhoneQuestion: undefined,
        whyWeValidatePhoneAnswerPart1: undefined,
        whyWeValidatePhoneAnswerPart2: undefined,
      },
      buttons: {
        haveAccountAlready: undefined,
      },
    },
    verifyPhone: {
      title: undefined,
      messages: {
        enterPhoneVerificationCode: undefined,
        haveYouNotReceivedSms: undefined,
      },
      buttons: {
        sendPhoneVerificationCodeAgain: undefined,
      },
    },
    selectUserType: {
      title: undefined,
      messages: {
        iam: undefined,
        helpUsAdopt: undefined,
        parent: undefined,
        student: undefined,
        schoolboy: undefined,
        institution: undefined,
        other: undefined,
      },
    },
  },
  offerRequestMessages: {
    requestDetails: {
      subjects: undefined,
      hoursPerMonth: undefined,
      teachingLevel: undefined,
      teachingLocation: undefined,
    },
    // seeker
    [OfferRequestType.SENT]: {
      [OfferRequestStatus.PENDING]: {
        label: undefined,
        labelSchool: undefined,
        title: undefined,
        description: undefined,
        descriptionSchool: undefined,
      },
      [OfferRequestStatus.ACCEPTED]: {
        label: undefined,
        labelSchool: undefined,
        title: undefined,
        description: undefined,
        descriptionSchool: undefined,
      },
      [OfferRequestStatus.REJECTED]: {
        label: undefined,
        labelSchool: undefined,
        title: undefined,
        description: undefined,
        descriptionSchool: undefined,
      },
    },
    // tutor
    [OfferRequestType.RECEIVED]: {
      [OfferRequestStatus.PENDING]: {
        label: undefined,
        labelSchool: undefined,
        title: undefined,
        description: undefined,
        descriptionSchool: undefined,
      },
      [OfferRequestStatus.ACCEPTED]: {
        label: undefined,
        labelSchool: undefined,
        title: undefined,
        description: undefined,
        descriptionSchool: undefined,
      },
      [OfferRequestStatus.REJECTED]: {
        label: undefined,
        labelSchool: undefined,
        title: undefined,
        description: undefined,
        descriptionSchool: undefined,
      },
    },
    unknown: undefined,
    yourContactDetailsHaveBeenSuccessfullyTransmitted: undefined,
    YouHaveDeclinedRequest: undefined,
    beforeAcceptOrRejectRequest: undefined,
    contactPopup: {
      title: undefined,
      noPhoneNumber: undefined,
      description: undefined,
    },
    anonymousUser: undefined,
  },
  seoRoute: {
    [AppRoute.Home]: {
      title: undefined,
      description: undefined,
    },
    [AppRoute.SearchLocalTutorsBySubject]: {
      title: undefined,
      description: undefined,
    },
    // when empty it will be taken from AppRoute.Home,
    [AppRoute.PublishOffer]: {
      title: undefined,
      description: undefined,
    },
    [AppRoute.EditOffer]: {
      title: undefined,
      description: undefined,
    },
    [AppRoute.SearchOnlineTutors]: {
      title: undefined,
      description: undefined,
    },
    [AppRoute.SearchLocalTutorsPerCity]: {
      title: undefined,
      description: undefined,
    },
    [AppRoute.SearchSchools]: {
      title: undefined,
      description: undefined,
    },
    [AppRoute.RefugeeInitiative]: {
      title: 'Німецька мова для біженців - знайти вчителів-волонтерів',
      description:
        'Широкий спектр приватних і добровільних німецьких учителів, які хочуть допомогти біженцям. Запитуйте зараз легко та безкоштовно для вчителів.',
    },
    [AppRoute.SearchRefugeesOnline]: {
      title: 'Безкоштовні уроки німецької мови - німецька для біженців',
      description:
        'lll➤ Індивідуальні уроки німецької мови онлайн для біженців ✓ Вчителі-волонтери ✓ Навчальні матеріали німецької мови ✓ Безкоштовне посередництво ✓ Спробуйте зараз безкоштовно!',
    },
    [AppRoute.SearchRefugeesPerCity]: {
      title: 'Волонтерські уроки німецької мови в {{city}} - німецька мова для біженців',
      description:
        'lll➤ {{city}}: Ви можете знайти широкий спектр волонтерів-вчителів німецької мови в {{city}} на Tutorspace. Запит контактної інформації для курсу німецької мови в {{city}} зараз.',
    },
    [AppRoute.TutorDetails]: {
      title: undefined,
      description: undefined,
    },
    [AppRoute.SchoolDetails]: {
      title: undefined,
      description: undefined,
    },
    [AppRoute.UserProfile]: {
      title: undefined,
      description: undefined,
    },
    [AppRoute.MyOfferRequests]: {
      title: undefined,
      description: undefined,
    },
    [AppRoute.MyOffer]: {
      title: undefined,
      description: undefined,
    },
    [AppRoute.ForTutorsBecomeTutor]: {
      title: undefined,
      description: undefined,
    },
    [AppRoute.ForStudentsTeachersNearYou]: {
      title: undefined,
      description: undefined,
    },
    [AppRoute.AboutTSHowDoesItWork]: {
      title: undefined,
      description: undefined,
    },
    [AppRoute.AboutTSForTutoringInstitutes]: {
      title: undefined,
      description: undefined,
    },
    [AppRoute.SupportAndHelpYouAskWeAnswer]: {
      title: undefined,
      description: undefined,
    },
    [AppRoute.SupportAndHelpContact]: {
      title: undefined,
      description: undefined,
    },
    [AppRoute.FooterImpressum]: {
      title: undefined,
      description: undefined,
    },
    [AppRoute.FooterTOS]: {
      title: undefined,
      description: undefined,
    },
    [AppRoute.FooterDataProtection]: {
      title: undefined,
      description: undefined,
    },
    [AppRoute.ServiceAndAdviceHowDoesItWork]: {
      title: undefined,
      description: undefined,
    },
    [AppRoute.GetStartedNowAdvertiseOffer]: {
      title: undefined,
      description: undefined,
    },
    [AppRoute.SetPassword]: {
      title: undefined,
      description: undefined,
    },
    [AppRoute.NotFound]: {
      title: undefined,
      description: undefined,
    },
  },
  static: {
    termsOfUse: {
      title: undefined,
      bodyHeading: undefined,
      providerHeading: undefined,
      scope: {
        heading: undefined,
        paragraphs: {
          paragraph1: undefined,
          paragraph2: undefined,
          paragraph3: undefined,
          paragraph4: undefined,
          paragraph5: undefined,
          paragraph6: undefined,
        },
      },
      description: {
        heading: undefined,
        paragraphs: {
          paragraph1: undefined,
          paragraph2: undefined,
          paragraph3: undefined,
          paragraph4: undefined,
          paragraph5: undefined,
          paragraph6: undefined,
          paragraph7: undefined,
          paragraph8: undefined,
          paragraph9: undefined,
          paragraph10: undefined,
        },
        link: undefined,
      },
      account: {
        heading: undefined,
        paragraphs: {
          paragraph1: undefined,
          paragraph2: undefined,
          paragraph3: undefined,
          paragraph4: undefined,
          paragraph5: undefined,
          paragraph6: undefined,
          paragraph7: undefined,
        },
      },
      obligations: {
        heading: undefined,
        paragraphs: {
          paragraph1: undefined,
          paragraph2: undefined,
          paragraph3: undefined,
        },
      },
      warrantyAndLiability: {
        heading: undefined,
        paragraphs: {
          paragraph1: undefined,
          paragraph2: undefined,
          paragraph3: undefined,
          paragraph4: undefined,
          paragraph5: undefined,
          paragraph6: undefined,
          paragraph7: undefined,
          paragraph8: undefined,
        },
      },
      dataProtection: {
        heading: undefined,
        paragraphs: {
          paragraph1: undefined,
          paragraph2: undefined,
          paragraph3: undefined,
          paragraph4: undefined,
        },
        link: undefined,
      },
      settlementOfDisputes: {
        heading: undefined,
        paragraphs: {
          paragraph1: undefined,
        },
      },
      finalProvisions: {
        heading: undefined,
        paragraphs: {
          paragraph1: undefined,
          paragraph2: undefined,
          paragraph3: undefined,
        },
      },
      updatedOn: undefined,
    },
    impressum: {
      title: undefined,
      operatorHeading: undefined,
      representedBy: undefined,
      headquarters: undefined,
      ceo: undefined,
      ceoRegisterNumber: undefined,
      phone: undefined,
      email: undefined,
      website: undefined,
      taxIdHeading: undefined,
      findDataProtection: undefined,
      contentManagement: undefined,
      onlineDispute: {
        heading: undefined,
        paragraph1: undefined,
      },
      imageSourcesHeading: undefined,
    },
    dataProtection: {
      applicationHeading: undefined,
      title: undefined,
      websiteHeading: undefined,
      preText: {
        paragraph1: undefined,
        paragraph2: undefined,
        paragraph3: undefined,
      },
      basics: {
        heading: undefined,
        paragraph1: undefined,
        bulletpoints: {
          bulletpoint1: undefined,
          bulletpoint2: undefined,
          bulletpoint3: undefined,
          bulletpoint4: undefined,
        },
        paragraph2: undefined,
      },
      operator: {
        heading: undefined,
        paragraph1: undefined,
        paragraph2: undefined,
      },
      dataCollection: {
        heading: undefined,
        paragraphs: {
          paragraph0: undefined,
          paragraph1: undefined,
          paragraph2: undefined,
          paragraph3: undefined,
          paragraph4: undefined,
          paragraph5: undefined,
          paragraph6: undefined,
          paragraph7: undefined,
          paragraph8: undefined,
          paragraph9: undefined,
          paragraph10: undefined,
          paragraph11: undefined,
          paragraph12: undefined,
          paragraph13: undefined,
          paragraph14: undefined,
          paragraph15: undefined,
          paragraph16: undefined,
          paragraph17: undefined,
          paragraph18: undefined,
          paragraph19: undefined,
          paragraph20: undefined,
          paragraph21: undefined,
          paragraph22: undefined,
          paragraph23: undefined,
          paragraph24: undefined,
          paragraph25: undefined,
        },
        bulletpoints: {
          bulletpoint1: undefined,
          bulletpoint2: undefined,
          bulletpoint3: undefined,
          bulletpoint4: undefined,
          bulletpoint5: undefined,
          bulletpoint6: undefined,
          bulletpoint7: undefined,
          bulletpoint8: undefined,
          bulletpoint9: undefined,
        },
      },
      legalBasisNew: {
        heading: undefined,
        preText: {
          paragraph1: undefined,
          paragraph2: undefined,
          bulletpoints: [undefined, undefined, undefined, undefined],
        },
        paragraphs: {
          paragraph: undefined,
          paragraph1: undefined,
          paragraph2: undefined,
          paragraph3: undefined,
          paragraph4: undefined,
          paragraph5: undefined,
          paragraph6: undefined,
          paragraph7: undefined,
          paragraph8: undefined,
          paragraph9: undefined,
          paragraph10: undefined,
          paragraph11: undefined,
          paragraph12: undefined,
          paragraph13: undefined,
          paragraph14: undefined,
          paragraph15: undefined,
          paragraph16: undefined,
          paragraph17: undefined,
          paragraph18: undefined,
          paragraph19: undefined,
          paragraph20: undefined,
          paragraph21: undefined,
          paragraph22: undefined,
          paragraph23: undefined,
          paragraph24: undefined,
          paragraph25: undefined,
          paragraph26: undefined,
          paragraph27: undefined,
          paragraph28: undefined,
          paragraph29: undefined,
        },
      },
      thirdparty: {
        headline: undefined,
        paragraphs: {
          paragraph1: undefined,
          paragraph2: undefined,
          paragraph3: undefined,
          paragraph4: undefined,
          paragraph5: undefined,
          paragraph6: undefined,
          paragraph7: undefined,
          paragraph8: undefined,
          paragraph9: undefined,
          paragraph10: undefined,
          paragraph11: undefined,
          paragraph12: undefined,
          paragraph13: undefined,
        },
      },
      dataStorage: {
        headline: undefined,
        paragraphs: {
          paragraph1: undefined,
          paragraph2: undefined,
          paragraph3: undefined,
          paragraph4: undefined,
        },
      },
      yourRightsNew: {
        headline: undefined,
        paragraphs: {
          paragraph1: undefined,
          paragraph2: undefined,
          paragraph3: undefined,
          paragraph4: undefined,
          paragraph5: undefined,
          paragraph7: undefined,
          paragraph8: undefined,
          paragraph9: undefined,
          paragraph10: undefined,
          paragraph11: undefined,
          paragraph12: undefined,
          paragraph13: undefined,
          paragraph14: undefined,
          paragraph15: undefined,
          paragraph16: undefined,
          paragraph17: undefined,
          paragraph18: undefined,
          paragraph19: undefined,
          paragraph20: undefined,
          paragraph21: undefined,
          paragraph22: undefined,
          paragraph23: undefined,
          paragraph24: undefined,
          paragraph25: undefined,
        },
      },
      changes: {
        headline: undefined,
        paragraphs: {
          paragraph1: undefined,
        },
      },
      general: {
        heading: undefined,
        paragraph: undefined,
      },
      responsibleBody: {
        heading: undefined,
        paragraph: undefined,
      },
      purposes: {
        heading: undefined,
        subsections: {
          contractProcessing: {
            heading: undefined,
            paragraph: undefined,
          },
          advertising: {
            heading: undefined,
            paragraph: undefined,
          },
          creditCheck: {
            heading: undefined,
            paragraph: undefined,
          },
          other: {
            heading: undefined,
            paragraph: undefined,
          },
        },
      },
      duration: {
        heading: undefined,
        paragraph: undefined,
      },
      legalBasis: {
        heading: undefined,
        paragraphs: {
          paragraph1: undefined,
          paragraph2: undefined,
          paragraph3: undefined,
          paragraph4: undefined,
          paragraph5: undefined,
        },
      },
      yourRights: {
        heading: undefined,
        subsections: {
          informationCorrection: {
            heading: undefined,
            paragraph: undefined,
          },
          directEmailObjection: {
            heading: undefined,
            paragraph: undefined,
          },
          withdrawal: {
            heading: undefined,
            paragraph: undefined,
          },
          complaints: {
            heading: undefined,
            paragraph: undefined,
          },
          contact: {
            heading: undefined,
            paragraph1: undefined,
            paragraph2: undefined,
          },
        },
      },
    },
  },
  sidebar: {
    custom: {
      promotion: undefined,
      donationInfo: undefined,
      help: {
        headline: undefined,
        textForSeeker: undefined,
        textForTutor: undefined,
      },
    },
    tutoringInstitute: {
      myOffers: undefined,
      myProfile: undefined,
      settings: undefined,
      messages: undefined,
    },
    regularUser: {
      myMessages: undefined,
      myOffer: undefined,
      myProfile: undefined,
    },
    elitetutor: {
      title: undefined,
      myAvailability: undefined,
      earnings: undefined,
      unitOverview: undefined,
    },
    eliteseeker: {
      title: undefined,
      unitOverview: undefined,
      billing: undefined,
      myPricing: undefined,
    },
  },
  notFoundPage: {
    backToHomepage: undefined,
  },
  date: {
    month_names: [
      null,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    ],
  },
  stickySideBar: {
    promotion_1: {
      title: undefined,
      headline: undefined,
      buttonText: undefined,
      textList: {
        0: {
          text: undefined,
        },
        1: {
          text: undefined,
        },
        2: {
          text: undefined,
        },
        3: {
          text: undefined,
        },
      },
    },
  },
  promotion_2: {
    headline: undefined,
    paragraphs: {
      paragraph1: undefined,
    },
    buttonText: undefined,
  },
  conference: {
    joinButton: undefined,
    socialCall: {
      title: undefined,
      infotext: undefined,
      at: undefined,
      noSocialCalls: undefined,
    },
    introductionCall: {
      title: undefined,
      infotext: undefined,
      noCalls: undefined,
      at: undefined,
    },
  },
  bookingForm: {
    default: {
      headline: undefined,
      subtext: undefined,
    },
    substitute: {
      headline: undefined,
    },
    myOffer: {
      headline: undefined,
      subtext: undefined,
      subjects: {
        label: undefined,
      },
      level: {
        label: undefined,
      },
      lessonDate: {
        label: undefined,
      },
      seekerNote: {
        label: undefined,
        placeholder: undefined,
      },
      seeker: {
        label: undefined,
        placeholder: undefined,
      },
    },
    trial: {
      headline: undefined,
      subtext: undefined,
      submit: undefined,
      submitNoAuth: undefined,
      submitAfterAuth: undefined,
    },
    subjects: {
      label: undefined,
      placeholder: undefined,
    },
    level: {
      label: undefined,
    },
    lessonDate: {
      label: undefined,
      placeholder: undefined,
      timeSlotLabel: undefined,
    },
    seekerNote: {
      label: undefined,
      placeholder: undefined,
    },
    button: {
      submit: undefined,
    },
    create: {
      responseOK: undefined,
      responseBadRequestError: undefined,
      customErrorCode: {
        BOOKING_CREDIT_INSUFFICIENT_SEEKER: undefined,
      },
    },
  },
  pricing: {
    headline: undefined,
    subheadline: undefined,
    infoBox: {
      bulletPoints: [undefined, undefined, undefined, undefined],
    },
    pricingModel: {
      default: {
        lesson: undefined,
        perWeek: undefined,
        startsAt: undefined,
        perUnit: undefined,
        selectPlan: undefined,
        selectedPlan: undefined,
        customSelector: undefined,
        mostSold: undefined,
      },
      item1: {
        title: undefined,
        description: undefined,
      },
      item2: {
        title: undefined,
        description: undefined,
      },
      item3: {
        title: undefined,
        description: undefined,
      },
      item4: {
        title: undefined,
        description: undefined,
      },
    },
    selection: {
      lessonUnitCount: {
        headline: undefined,
        desciption: undefined,
        lessonUnit: undefined,
        hint: undefined,
      },
      contractPeriod: {
        headline: undefined,
        month: undefined,
        months: undefined,
        description: [undefined, undefined, undefined],
      },
      summary: {
        lessonUnitsPerMonth: undefined,
        regularPrice: undefined,
        regularPricePerMonth: undefined,
        yourPrice: undefined,
        discount: undefined,
        total: undefined,
      },
      formError: undefined,
      button: {
        confirm: undefined,
        change: undefined,
      },
      delayNoticeSEPA: undefined,
      promotionBox: {
        headline: undefined,
        text1: undefined,
        text2: undefined,
      },
      quotation: {
        quote: undefined,
        user: undefined,
      },
    },
    success: undefined,
    revokedSubscriptionChange: undefined,
    noPricingAvailable: {
      headline: undefined,
    },
    subscriptionChangePreview: {
      title: undefined,
      header: undefined,
      text: undefined,
      confirmPreviewText: undefined,
      timeoutWarningText: undefined,
      timeoutErrorText: undefined,
    },
  },
  upComingLessons: {
    headline: undefined,
    upcoming: undefined,
    past: undefined,
    lessonCard: {
      level: undefined,
      time: undefined,
      date: undefined,
      type: undefined,
      promotion: undefined,
      startLesson: undefined,
      cancelLesson: undefined,
      contactDetails: undefined,
      trialLesson: undefined,
      regularLesson: undefined,
      bookNewLesson: undefined,
      recording: {
        title: undefined,
        recording: undefined,
        notAvailable: undefined,
        notAvailableReason: undefined,
        recordPartInfo: undefined,
      },
      cancelLessonWarning: {
        warning: undefined,
        reminderCreditExpiry: undefined,
        reminderCreditExpiryTutor: undefined,
        reminderMinBefore: undefined,
      },
      cancelTooLate: undefined,
      cancelSuccessful: undefined,
    },
    infoBox: {
      tutor: {
        heading: undefined,
        paragraphs: {
          paragraph1: undefined,
          paragraph2: undefined,
          paragraph3: undefined,
          paragraph4: undefined,
        },
      },
      seeker: {
        heading: undefined,
        paragraphs: {
          paragraph1: undefined,
          paragraph2: undefined,
          paragraph3: undefined,
          paragraph4: undefined,
          paragraph5: undefined,
        },
      },
    },
  },
  billing: {
    subscriptionDetails: {
      currentSubscription: undefined,
      lessonUnitsLeft: undefined,
      editSubscrption: undefined,
      revokeChangeSubscrption: undefined,
      nextBilling: undefined,
      autoRenew: undefined,
      canceledBilling: undefined,
    },
    bookingHistory: {
      title: undefined,
      yourTutor: undefined,
      yourSeeker: undefined,
      time: undefined,
      date: undefined,
      lessonType: undefined,
      subject: undefined,
      lessonUnit: undefined,
      receivedCredits: undefined,
      receivedCreditsFromCanceledBooking: undefined,
      tutorNoShowRefund: undefined,
      bothNoShowRefund: undefined,
      expiredCredits: undefined,
      correction: undefined,
      correctionNoteByAdmin: undefined,
      freeBookingGranted: undefined,
      freeBookingRevoked: undefined,
      delayNoticeSEPA: undefined,
    },
    infoBox: {
      heading: undefined,
      paragraphs: {
        paragraph1: undefined,
        paragraph2: undefined,
        paragraph3: undefined,
        paragraph4: undefined,
      },
    },
  },
  availbilityPage: {
    infobox1: undefined,
    infobox2: undefined,
  },
  earnings: {
    headline: undefined,
    infoBox: {
      currentEarnings: undefined,
      infoText: undefined,
      infoTextPart1: undefined,
      manualPayoutLink: undefined,
      infoTextPart2: undefined,
      addPayoutInfoButton: undefined,
      updatePayoutInfoButton: undefined,
      warningupdatePayoutInfo: undefined,
      tips: {
        heading: undefined,
        paragraphs: {
          paragraph1: undefined,
          paragraph2: undefined,
          paragraph3: undefined,
        },
      },
    },
    manualPayout: {
      title: undefined,
      areYouSure: undefined,
      areYouSureInfo: undefined,
      updateSuccess: undefined,
    },
    updatePayoutInfo: {
      title: undefined,
      updateSuccess: undefined,
      bankInfoTitle: undefined,
      addressTitle: undefined,
      accountHolder: {
        label: undefined,
      },
      iban: {
        label: undefined,
      },
      bic: {
        label: undefined,
      },
      smallBusinessRegulation: {
        paragraph: undefined,
        label: undefined,
        placeholder: undefined,
      },
      taxNumber: {
        label: undefined,
        placeholder: undefined,
      },
      street: {
        label: undefined,
        placeholder: undefined,
      },
      houseNumber: {
        label: undefined,
        placeholder: undefined,
      },
      postcode: {
        label: undefined,
        placeholder: undefined,
      },
      city: {
        label: undefined,
        placeholder: undefined,
      },
      country: {
        label: undefined,
        placeholder: undefined,
        defaultValue: undefined,
      },
    },
    deletePayoutInfo: {
      success: undefined,
    },
    eventText: {
      bonusTrial: undefined,
      penalty: undefined,
      bothNoShow: undefined,
      seekerNoShow: undefined,
      payout: undefined,
      receipt: undefined,
      correction: undefined,
    },
  },
  mySubjects: {
    infoBoxTop: undefined,
    infoBoxSide: {
      heading: undefined,
      paragraphs: {
        paragraph1: undefined,
        paragraph2: undefined,
        paragraph3: undefined,
        paragraph4: undefined,
        paragraph5: undefined,
      },
    },
  },
  exitIntentModal: {
    headline: undefined,
    subheadline: undefined,
    content: {
      bulletpoints: [undefined, undefined, undefined, undefined, undefined],
    },
    button: undefined,
  },
  jobs: {
    offer: undefined,
    initiativeApplication: undefined,
    initiativeApplicationButton: undefined,
  },
  customHttpErrors: {
    conflictErrors: {
      MISSING_VACANCY: undefined,
      OTHER_BOOKING: undefined,
      CONCURRENT_MODIFICATION: undefined,
      FREE_BOOKING_UNAVAILABLE: undefined,
      FREE_BOOKING_REQUIRED: undefined,
      BOOKING_CREDIT_INSUFFICIENT: undefined,
      SUBSCRIPTION_PLAN_MODIFIED: undefined,
      BOOKING_CANCELLATION_ALREADY_REQUESTED: undefined,
      PAYOUT_ALREADY_REQUESTED: undefined,
      LAST_PAYOUT_TOO_RECENT: undefined,
      PAYOUT_INFO_MISSING: undefined,
      PROFILE_VIDEO_PENDING_APPROVAL: undefined,
      QUOTA_EXHAUSTED: undefined,
      ALREADY_SUBSCRIBED: undefined,
      NOT_SUBSCRIBED: undefined,
      MORE_THAN_ONCE_SUBSCRIBED: undefined,
      CURRENT_SUBSCRIPTION_CHANGED: undefined,
      SUBSCRIPTION_CHANGE_NO_ACTUAL_CHANGE: undefined,
    },
    badRequestErrors: {
      VACANCY_SLOT_COUNT_WRONG: undefined,
      VACANCY_BEFORE_THRESHOLD: undefined,
      VACANCY_AFTER_THRESHOLD: undefined,
      VACANCY_TOO_SHORT: undefined,
      VACANCY_NOT_SLOT_ALIGNED: undefined,
      BOOKING_BEFORE_THRESHOLD: undefined,
      BOOKING_AFTER_THRESHOLD: undefined,
      BOOKING_TOO_SHORT: undefined,
      BOOKING_NOT_SLOT_ALIGNED: undefined,
      SUBSCRIPTION_PLAN_NOT_FOUND: undefined,
      PHONENUMBER_CHANGE_TIMEOUT: undefined,
      INVALID_IMAGE_URL: undefined,
    },
    forbiddenErrors: {
      WRONG_ACCOUNT_TYPE_TUTORING_INSTITUTE: undefined,
      NOT_CURRENT_USER: undefined,
      BOOKING_OWN_USER: undefined,
      CANCEL_BOOKING_START_TOO_EARLY: undefined,
      CANCEL_BOOKING_FREE: undefined,
    },
    unsupportedMediaTypeErrors: {
      UNSUPPORTED_MEDIA_TYPE: undefined,
      MEDIA_INFO_INVALID: undefined,
      VIDEO_DURATION_LIMIT_EXCEEDED: undefined,
    },
  },
};
