import { AppRoute } from 'app/app.route.const';
import { TutoringKind } from 'components/search/search.const';
import { SearchFormValues } from 'components/search/search.logic';
import { SearchOffersRequest } from 'logic/api-models/api-models';
import { OfferType } from 'logic/api-models/offer-type';
import { TutoringType } from 'logic/api-models/tutoring-type';
import { slugifyText } from 'logic/slugify-text/slugify-text';
import { parseQueryString } from 'logic/store/navigation/navigation.logic';
import { generatePath } from 'react-router-dom';
import { OFFERS_PAGE_SIZE, SEARCH_DISTANCE_IN_KM } from './search-offer.const';
import { SEARCH_SORT_BY_DEFAULT, SortOffersBy } from 'logic/api-models/sort-offer-by';
import { AlternativeOffersKey } from '../offer.model';
import { logError } from 'logic/log/log.logic';
import { translate, translationKeys } from 'logic/translations/translations.service';
import { TeachingLevel, TutoringRequestTeachingLevel } from 'logic/api-models/teaching-level';
import { SubjectName } from '../../../../models/subject-type';
import { MapBoxPlace, MapBoxPlacesFeature } from 'logic/store/geolocation/geolocation.model';

export const mapSearchFormToRequest = (
  formValues: SearchFormValues | undefined
): SearchOffersRequest => ({
  page: formValues?.page || 1,
  size: formValues?.size || OFFERS_PAGE_SIZE,
  subjects: !!formValues?.subject?.subjectNumber ? [formValues?.subject?.subjectNumber] : undefined,
  lat: formValues?.latitude,
  lng: formValues?.longitude,
  distanceInKms: SEARCH_DISTANCE_IN_KM,
  tutoringType: getSearchRequestTutoringType(formValues?.kind),
  offerType: getOfferType(formValues?.kind),
  sortBy: getSortBy(formValues?.sortBy, formValues?.cityId),
  teachingLevel: mapTutoringRequestTeachingLevelToTeachingLevel(formValues?.requestTeachingLevel),
});

const getSearchRequestTutoringType = (kind: TutoringKind | undefined) =>
  kind === TutoringKind.onlineTutoring
    ? [TutoringType.online]
    : [TutoringType.atTeacher, TutoringType.atStudent];

const getOfferType = (kind: TutoringKind | undefined) =>
  kind === TutoringKind.tutoringSchools
    ? [OfferType.School]
    : [OfferType.Regular, OfferType.School];

const getSortBy = (sortBy: SortOffersBy | undefined, cityId: string | undefined) =>
  (sortBy === SortOffersBy.Distance && !cityId) || sortBy === SortOffersBy.Newest
    ? undefined
    : sortBy;

export const getSearchResultsPath = (formValues: SearchFormValues | undefined) => {
  let { kind, city, subjectName, page, requestTeachingLevel, sortBy = SEARCH_SORT_BY_DEFAULT } =
    formValues || {};
  page = page && page > 1 ? page : undefined;

  const subject = slugifyText(subjectName);
  const citySlug = slugifyText(city?.labelShort);
  const cityId = city?.id;
  const online = kind === TutoringKind.onlineTutoring;
  if (typeof requestTeachingLevel === 'number') {
    requestTeachingLevel = !isNaN(requestTeachingLevel) ? requestTeachingLevel : 1;
  }

  if ((!subject && online) || (!subject && !citySlug)) {
    logError(translate(translationKeys.errors.missingValues));
    return '';
  }

  if (subjectName === SubjectName.GermanForRefugees) {
    if (subjectName === SubjectName.GermanForRefugees && !online && !citySlug) {
      logError(translate(translationKeys.errors.missingCity));
      return '';
    }

    switch (kind) {
      case TutoringKind.onSiteTutoring:
        return `${generatePath(AppRoute.SearchRefugeesPerCity, {
          city: citySlug,
        })}${parseQueryString({
          page,
          sortBy,
          cityId,
          requestTeachingLevel,
        })}`;
      case TutoringKind.onlineTutoring:
        return `${generatePath(AppRoute.SearchRefugeesOnline, {
          city: citySlug,
        })}${parseQueryString({
          page,
          sortBy,
          cityId,
          requestTeachingLevel,
        })}`;
      default:
        return undefined;
    }
  }
  switch (kind) {
    case TutoringKind.onSiteTutoring:
      return citySlug
        ? `${generatePath(
            subject
              ? AppRoute.SearchLocalTutorsPerCityAndSubject
              : AppRoute.SearchLocalTutorsPerCity,
            {
              city: citySlug,
              subject,
            }
          )}${parseQueryString({
            page,
            sortBy,
            cityId,
            requestTeachingLevel,
          })}`
        : `${generatePath(AppRoute.SearchLocalTutorsBySubject, {
            subject,
          })}${parseQueryString({ page, sortBy, requestTeachingLevel })}`;
    case TutoringKind.onlineTutoring:
      return `${generatePath(AppRoute.SearchOnlineTutors, {
        subject,
      })}${parseQueryString({ page, sortBy, requestTeachingLevel })}`;
    case TutoringKind.tutoringSchools:
      return citySlug
        ? `${generatePath(AppRoute.SearchSchools, {
            city: citySlug,
          })}${parseQueryString({
            subject,
            page,
            sortBy,
            cityId,
            requestTeachingLevel,
          })}`
        : undefined;
    default:
      return undefined;
  }
};

export const getSearchAlternativeOffersTrackName = (
  actionName: string,
  key: AlternativeOffersKey
) => `${actionName}-${key}`;

export const getSearchFormValuesForAlternativeTutoringKind = (
  kind: TutoringKind | undefined,
  alternativeOffersKey: AlternativeOffersKey
) => {
  switch (kind) {
    case TutoringKind.onSiteTutoring:
      switch (alternativeOffersKey) {
        case AlternativeOffersKey.AlternativeOffersFirst:
          return TutoringKind.onlineTutoring;
        case AlternativeOffersKey.AlternativeOffersSecond:
          return TutoringKind.tutoringSchools;
      }
    case TutoringKind.tutoringSchools:
      switch (alternativeOffersKey) {
        case AlternativeOffersKey.AlternativeOffersFirst:
          return TutoringKind.onSiteTutoring;
        case AlternativeOffersKey.AlternativeOffersSecond:
          return TutoringKind.onlineTutoring;
      }
  }
  return kind;
};

export const mapSearchFormToEliteRequest = (
  formValues: SearchFormValues | undefined
): SearchOffersRequest => ({
  page: formValues?.page || 1,
  size: formValues?.size || OFFERS_PAGE_SIZE,
  eliteSubjects: !!formValues?.subject?.subjectNumber
    ? [formValues?.subject?.subjectNumber]
    : undefined,
  tutoringType: [TutoringType.online, TutoringType.atStudent, TutoringType.atTeacher],
  offerType: [OfferType.Elitetutoring],
  sortBy: getEliteSortBy(formValues?.sortBy),
  teachingLevel: mapTutoringRequestTeachingLevelToTeachingLevel(formValues?.requestTeachingLevel),
});

const getEliteSortBy = (sortBy: SortOffersBy | undefined) => {
  if (sortBy === SortOffersBy.Newest) return undefined;
  if (sortBy === SortOffersBy.Rating) return sortBy;
  return SortOffersBy.Default;
};

export const getEliteSearchResultsPath = (formValues: SearchFormValues | undefined) => {
  let { subjectName, page, requestTeachingLevel, sortBy = SEARCH_SORT_BY_DEFAULT } =
    formValues || {};
  page = page && page > 1 ? page : undefined;

  const subject = slugifyText(subjectName);
  if (typeof requestTeachingLevel === 'number') {
    requestTeachingLevel = !isNaN(requestTeachingLevel) ? requestTeachingLevel : 4;
  }
  if (!subject) {
    logError(translate(translationKeys.errors.missingValues));
    return '';
  }

  return `${generatePath(AppRoute.SearchEliteTutors, { subject })}${parseQueryString({
    page,
    sortBy,
    requestTeachingLevel,
  })}`;
};

export const mapTutoringRequestTeachingLevelToTeachingLevel = (
  tutoringRequestTeachingLevel?: TutoringRequestTeachingLevel
): TeachingLevel => {
  switch (tutoringRequestTeachingLevel) {
    case TutoringRequestTeachingLevel.CLASS1:
    case TutoringRequestTeachingLevel.CLASS2:
    case TutoringRequestTeachingLevel.CLASS3:
    case TutoringRequestTeachingLevel.CLASS4:
      return TeachingLevel.klasse4;
    case TutoringRequestTeachingLevel.CLASS5:
    case TutoringRequestTeachingLevel.CLASS6:
    case TutoringRequestTeachingLevel.CLASS7:
      return TeachingLevel.klasse7;
    case TutoringRequestTeachingLevel.CLASS8:
    case TutoringRequestTeachingLevel.CLASS9:
    case TutoringRequestTeachingLevel.CLASS10:
      return TeachingLevel.klasse10;
    case TutoringRequestTeachingLevel.CLASS11:
    case TutoringRequestTeachingLevel.CLASS12:
    case TutoringRequestTeachingLevel.ABITUR:
      return TeachingLevel.abitur;
    case TutoringRequestTeachingLevel.UNIVERSITY:
      return TeachingLevel.universtitatStudium;
    default:
      return TeachingLevel.klasse4;
  }
};

export const allowedMapBoxPlaceTypes = (searchPhrase: string) => {
  return isCityBerlin(searchPhrase)
    ? [MapBoxPlace.City, MapBoxPlace.Locality]
    : [MapBoxPlace.City];
};

export const isCityBerlin = (city?: string) => {
  return /^berlin-/i.test(city || '');
};

export const isBerlinOrNotLocality = (cityFeature: MapBoxPlacesFeature) =>
  cityFeature.place_name?.endsWith('Berlin, Deutschland') ||
  !cityFeature.place_type?.includes(MapBoxPlace.Locality);
