import { generatePath } from 'react-router-dom';
import { combineEpics, StateObservable } from 'redux-observable';
import { EMPTY, from, of, pipe } from 'rxjs';
import {
  filter,
  ignoreElements,
  map,
  mergeMap,
  switchMap,
  tap,
  throttleTime,
} from 'rxjs/operators';
import { AppRoute } from 'app/app.route.const';
import { logError, logSuccess } from 'logic/log/log.logic';
import { translate, translationKeys } from 'logic/translations/translations.service';
import { OfferRequestStatus } from 'logic/api-models/offer-request-status';
import { fillReduxForm } from 'logic/redux-form/fill-redux-form';
import { isBadRequestError } from 'logic/error/bad-request.error';
import { sentryErrorHandler } from 'logic/error/sentry.error';
import {
  AnalyticsEvent,
  PixelEvents,
  triggerAnalyticsEvent,
  triggerFBPixelEvent,
} from 'logic/analytics/analytics';
import { mapIdAndFileToLink } from '../file/file.logic';
import { getAuthenticatedUser } from '../authentication/authentication.selectors';
import { authenticationSlice } from '../authentication/authentication.slice';
import { Feature } from '../authentication/feature/authorization.feature';
import { API_MULTIPLY_CALLS_TIMEOUT_MS } from '../const';
import { navigationSlice } from '../navigation/navigation.slice';
import { mapOfferFormToRequest, mapOfferResponseToForm } from './offer.form.logic';
import { offerSlice } from './offer.slice';
import { modalSlice } from 'logic/store/modal/modal.slice';
import { handleDeleteOfferDialog } from 'logic/store/offer/delete-offer.logic';
import { RootEpic } from 'app/app.epics.type';
import { Dispatch } from 'redux';
import { StoreState } from 'app/app.reducers';
import {
  MY_OFFER_FORM_NAME,
  MyOfferFormValues,
  validateMyOfferForm,
} from 'src/pages-all/offer-form/my-offer-form-page/my-offer-form.logic';
import { AuthenticationApi } from '../authentication/authentication.api';
import { FileApi } from '../file/file.api';
import { offerAlreadyReportedError } from 'logic/error/offer-already-reported.error';
import { logSlice } from '../log/log.slice';
import { searchOfferEpic$ } from './search-offer/search-offer.epic';
import { ratingSlice } from '../rating/rating.slice';
import { isUnsupportedMediaTypeError } from 'logic/error/general-api-error';
import axios from 'axios';
import { getFormValues } from 'redux-form';
import { REQUEST_TUTORING_FORM_NAME } from 'src/pages-all/details/tutor/components/RequestTutoringModal/request-tutoring-form.logic';
import { RegisterAsElitetutorPaths } from 'src/pages-all/register-as-elitetutor/register-as-elitetutor.const';
import {
  REGISTER_ELITETUTOR_PHOTO_FORM,
  validateRegisterAsElitetutorPhotoForm,
} from 'src/pages-all/register-as-elitetutor/forms/register-elitetutor-photo-form.logic';

const fetchOffer$: RootEpic = (action$, _, { dispatch, managed, offerApi }) =>
  action$.pipe(
    filter(offerSlice.actions.fetchOffer.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    managed(
      offerSlice.actions.fetchOffer,
      switchMap((action) => from(offerApi.fetchOffer(action.payload.offerId || ''))),
      [handleFetchOfferError(dispatch)]
    ),
    tap((response) => {
      triggerAnalyticsEvent(AnalyticsEvent.SeekerHasViewOffer);
      dispatch(offerSlice.actions.setOffer({ offer: response.data.data }));
    }),
    ignoreElements()
  );

const handleFetchOfferError = (dispatch: Dispatch) => (error: any) => {
  if (isBadRequestError(error)) {
    sentryErrorHandler(error);
    dispatch(navigationSlice.actions.navigateTo({ path: AppRoute.NotFound }));
    return true;
  }
  return false;
};

const requestOfferImmediately$: RootEpic = (
  action$,
  state$,
  { dispatch, managed, offerApi, ofIsAuthenticated, ofIsAuthorized }
) =>
  action$.pipe(
    filter(offerSlice.actions.requestOfferImmediately.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    ofIsAuthenticated(),
    ofIsAuthorized(Feature.RequestOffer),
    managed(
      offerSlice.actions.requestOfferImmediately,
      switchMap(({ payload: { request } }) => from(offerApi.requestOffer(request)))
    ),
    mergeMap(() => {
      triggerFBPixelEvent(PixelEvents.Contact);
      triggerAnalyticsEvent(AnalyticsEvent.SeekerHasRequestedTutor);

      return of(
        offerSlice.actions.clearOfferRequestInitValues(),
        logSlice.actions.logSuccess({
          message: translate(
            translationKeys.confirmation.yourRequestForThisOfferWasSendSuccessfully
          ),
        })
      );
    })
  );

const requestOffer$: RootEpic = (
  action$,
  state$,
  { dispatch, managed, offerApi, ofIsAuthenticated, ofIsAuthorized }
) =>
  action$.pipe(
    filter(offerSlice.actions.requestOffer.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    ofIsAuthenticated(),
    ofIsAuthorized(Feature.RequestOffer),
    managed(
      offerSlice.actions.requestOffer,
      switchMap(() =>
        from(offerApi.requestOffer(getFormValues(REQUEST_TUTORING_FORM_NAME)(state$.value)))
      )
    ),
    mergeMap(() => {
      triggerFBPixelEvent(PixelEvents.Contact);
      triggerAnalyticsEvent(AnalyticsEvent.SeekerHasRequestedTutor);

      return of(
        modalSlice.actions.closeRequestTutoringModal(),
        offerSlice.actions.clearOfferRequestInitValues(),
        logSlice.actions.logSuccess({
          message: translate(
            translationKeys.confirmation.yourRequestForThisOfferWasSendSuccessfully
          ),
        })
      );
    })
  );

const fetchOfferRequests$: RootEpic = (
  action$,
  _,
  { managed, offerApi, ofIsAuthenticated, ofIsAuthorized }
) =>
  action$.pipe(
    filter(offerSlice.actions.fetchOfferRequests.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    ofIsAuthenticated(),
    ofIsAuthorized(Feature.MyMessages),
    managed(
      offerSlice.actions.fetchOfferRequests,
      switchMap(() => from(offerApi.fetchOfferRequests()))
    ),
    map((response) =>
      offerSlice.actions.setOfferRequests({
        offerRequests: response.data.data,
      })
    )
  );

const rejectOfferRequest$: RootEpic = (
  action$,
  _,
  { dispatch, managed, offerApi, ofIsAuthenticated, ofIsAuthorized }
) =>
  action$.pipe(
    filter(offerSlice.actions.rejectOfferRequest.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    ofIsAuthenticated(),
    ofIsAuthorized(Feature.MyMessages),
    managed(
      offerSlice.actions.rejectOfferRequest,
      switchMap((action) =>
        from(
          offerApi.updateOfferRequest(action.payload.offerRequestId, {
            status: OfferRequestStatus.REJECTED,
          })
        )
      )
    ),
    tap(() => {
      triggerAnalyticsEvent(AnalyticsEvent.TutorHasDeclinedSeekerRequest);
      dispatch(offerSlice.actions.fetchOfferRequests());
      logSuccess(translate(translationKeys.confirmation.youHaveRejectedRequestOfferSuccessfully));
    }),
    ignoreElements()
  );

const acceptOfferRequest$: RootEpic = (
  action$,
  _,
  { dispatch, managed, offerApi, ofIsAuthenticated, ofIsAuthorized }
) =>
  action$.pipe(
    filter(offerSlice.actions.acceptOfferRequest.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    ofIsAuthenticated(),
    ofIsAuthorized(Feature.MyMessages),
    managed(
      offerSlice.actions.acceptOfferRequest,
      switchMap((action) =>
        from(
          offerApi.updateOfferRequest(action.payload.offerRequestId, {
            status: OfferRequestStatus.ACCEPTED,
          })
        )
      )
    ),
    tap((response) => {
      triggerAnalyticsEvent(AnalyticsEvent.TutorHasAcceptedSeekerRequest);
      dispatch(offerSlice.actions.fetchOfferRequests());
      logSuccess(translate(translationKeys.confirmation.youHaveAcceptedRequestOfferSuccessfully));
    }),
    ignoreElements()
  );

const fetchOfferForEdit$: RootEpic = (action$, state$, { managed, authenticationApi }) =>
  action$.pipe(
    filter(offerSlice.actions.fetchOfferForEdit.match),
    managed(
      offerSlice.actions.fetchOfferForEdit,
      switchMap(() => from(authenticationApi.fetchCurrentUser()))
    ),
    map((response) =>
      fillReduxForm(MY_OFFER_FORM_NAME, mapOfferResponseToForm(state$.value), response.data.data)
    )
  );

const createMyOffer$: RootEpic = (
  action$,
  state$,
  {
    dispatch,
    managed,
    ofValidReduxForm,
    authenticationApi,
    offerApi,
    fileApi,
    ofIsAuthenticated,
    ofIsAuthorized,
  }
) =>
  action$.pipe(
    filter(offerSlice.actions.createMyOffer.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    ofIsAuthenticated(),
    ofIsAuthorized(Feature.MyOffer),
    ofValidReduxForm(MY_OFFER_FORM_NAME, validateMyOfferForm),
    managed(
      offerSlice.actions.createMyOffer,
      pipe(
        map((action) => action.payload.formValues),
        uploadUserPhoto(fileApi),
        updateUserPhotoAndAge(state$, authenticationApi),
        uploadUserVideo(state$, fileApi),
        switchMap((formValues) => from(offerApi.createMyOffer(mapOfferFormToRequest(formValues)))),
        switchMap(() => from(authenticationApi.fetchCurrentUser())),
        tap((response) =>
          dispatch(authenticationSlice.actions.setUser({ user: response.data.data }))
        )
      )
    ),
    tap(() => {
      triggerAnalyticsEvent(AnalyticsEvent.TutorHasPublishedOffer);
      triggerFBPixelEvent(PixelEvents.Lead);
      dispatch(
        navigationSlice.actions.navigateTo({
          path: generatePath(AppRoute.MyOffer),
        })
      );
      logSuccess(translate(translationKeys.confirmation.yourOfferWasSuccessfullyPublished));
    }),
    ignoreElements()
  );

const createOfferElitetutorRegistration$: RootEpic = (
  action$,
  state$,
  {
    dispatch,
    managed,
    authenticationApi,
    offerApi,
    fileApi,
    ofIsAuthenticated,
    ofIsAuthorized,
    ofValidReduxForm,
  }
) =>
  action$.pipe(
    filter(offerSlice.actions.createOfferElitetutorRegistration.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    ofIsAuthenticated(),
    ofIsAuthorized(Feature.MyOffer),
    ofValidReduxForm(REGISTER_ELITETUTOR_PHOTO_FORM, validateRegisterAsElitetutorPhotoForm),
    managed(
      offerSlice.actions.createOfferElitetutorRegistration,
      pipe(
        map((action) => action.payload.formValues),
        uploadUserPhoto(fileApi),
        updateUserPhotoAndAge(state$, authenticationApi),
        switchMap((formValues) => from(offerApi.createMyOffer(mapOfferFormToRequest(formValues)))),
        switchMap(() => from(authenticationApi.fetchCurrentUser())),
        tap((response) =>
          dispatch(authenticationSlice.actions.setUser({ user: response.data.data }))
        )
      )
    ),
    tap(() => {
      triggerAnalyticsEvent(AnalyticsEvent.TutorHasPublishedOffer);
      triggerFBPixelEvent(PixelEvents.Lead);
      dispatch(
        navigationSlice.actions.navigateTo({
          path: `${AppRoute.TutorspacePremiumRegisterAsTutor}/${RegisterAsElitetutorPaths.Done}`,
        })
      );
    }),
    ignoreElements()
  );

const updateMyOffer$: RootEpic = (
  action$,
  state$,
  {
    managed,
    ofValidReduxForm,
    authenticationApi,
    offerApi,
    fileApi,
    ofIsAuthenticated,
    ofIsAuthorized,
  }
) =>
  action$.pipe(
    filter(offerSlice.actions.updateMyOffer.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    ofIsAuthenticated(),
    ofIsAuthorized(Feature.MyOffer),
    ofValidReduxForm(MY_OFFER_FORM_NAME, validateMyOfferForm),
    managed(
      offerSlice.actions.updateMyOffer,
      pipe(
        map((action) => action.payload.formValues),
        uploadUserPhoto(fileApi),
        updateUserPhotoAndAge(state$, authenticationApi),
        uploadUserVideo(state$, fileApi),
        switchMap((formValues) =>
          from(offerApi.updateMyOffer(formValues?._id || '', mapOfferFormToRequest(formValues)))
        ),
        switchMap(() => from(authenticationApi.fetchCurrentUser()))
      ),
      [
        (error) => {
          const errorcode = error?.response?.data?.error;
          const unsupportedMediaTypeErrorCodeList = [
            'UNSUPPORTED_MEDIA_TYPE',
            'MEDIA_INFO_INVALID',
            'VIDEO_DURATION_LIMIT_EXCEEDED',
          ];
          if (
            error &&
            isUnsupportedMediaTypeError(error) &&
            axios.isAxiosError(error) &&
            unsupportedMediaTypeErrorCodeList.includes(errorcode)
          ) {
            logError(
              translate(translationKeys.publishOffer.error.unsupportedMediaType[errorcode], {
                errorDetail: error?.response?.data?.errorDetail
                  ? `(${error?.response?.data?.errorDetail})`
                  : '',
              })
            );
            return true;
          }
          return false;
        },
      ]
    ),
    tap(() => logSuccess(translate(translationKeys.confirmation.yourOfferWasSuccessfullyUpdated))),
    mergeMap((response) =>
      of(
        authenticationSlice.actions.setUser({ user: response.data.data }),
        navigationSlice.actions.navigateTo({ path: generatePath(AppRoute.MyOffer) })
      )
    )
  );

const tryRemoveMyOfferEpic$: RootEpic = (action$, _, { managed, dispatch }): any =>
  action$.pipe(
    filter(offerSlice.actions.tryRemoveOffer.match),
    switchMap(({ payload }) => {
      handleDeleteOfferDialog({
        onDialogResponseOK: () => {
          dispatch(offerSlice.actions.removeOffer(payload as string));
        },
      });

      return EMPTY;
    })
  );

const removeMyOfferEpic$: RootEpic = (
  action$,
  _,
  { managed, authenticationApi, offerApi, ofIsAuthenticated }
) =>
  action$.pipe(
    filter(offerSlice.actions.removeOffer.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    ofIsAuthenticated(),
    managed(
      offerSlice.actions.removeOffer,
      pipe(
        switchMap((action) => from(offerApi.removeOffer(action.payload))),
        switchMap(() => from(authenticationApi.fetchCurrentUser())),
        switchMap((response) =>
          of(authenticationSlice.actions.setUser({ user: response.data.data }))
        )
      )
    )
  );

const uploadUserPhoto = (fileApi: FileApi) =>
  switchMap((formValues: MyOfferFormValues | undefined) =>
    formValues?.photo?.file
      ? from(fileApi.uploadFile(formValues?.photo?.file)).pipe(
          map((response) => ({
            ...formValues,
            photo: {
              url: mapIdAndFileToLink(response.data.data?._id, formValues.photo?.file?.name),
            },
          }))
        )
      : of(formValues)
  );

const uploadUserVideo = (state$: StateObservable<StoreState>, fileApi: FileApi) =>
  switchMap((formValues: MyOfferFormValues | undefined) => {
    const userId = getAuthenticatedUser(state$.value)?._id || '';
    return formValues?.video?.file
      ? from(fileApi.uploadVideo(formValues?.video?.file, userId)).pipe(
          map((response) => ({
            ...formValues,
          }))
        )
      : of(formValues);
  });

const updateUserPhotoAndAge = (
  state$: StateObservable<StoreState>,
  authenticationApi: AuthenticationApi
) =>
  switchMap((formValues: MyOfferFormValues | undefined) => {
    const userId = getAuthenticatedUser(state$.value)?._id || '';
    const request = {
      yearOfBirth: formValues?.yearOfBirth,
      imageUrl: formValues?.photo?.url,
    };
    return from(authenticationApi.updateUser(userId, request)).pipe(map(() => formValues));
  });

const updateOfferStatus$: RootEpic = (
  action$,
  _,
  { dispatch, managed, authenticationApi, offerApi, ofIsAuthenticated, ofIsAuthorized }
) =>
  action$.pipe(
    filter(offerSlice.actions.updateOfferStatus.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    ofIsAuthenticated(),
    ofIsAuthorized(Feature.MyOffer),
    managed(
      offerSlice.actions.updateOfferStatus,
      pipe(
        switchMap((action) =>
          from(
            offerApi.updateMyOffer(action.payload.offerId, {
              isPublished: action.payload.isPublished,
            })
          )
        ),
        switchMap(() => from(authenticationApi.fetchCurrentUser()))
      )
    ),
    tap((response) => {
      dispatch(authenticationSlice.actions.setUser({ user: response.data.data }));
      logSuccess(translate(translationKeys.confirmation.yourOfferWasSuccessfullyUpdated));
    }),
    ignoreElements()
  );

const reportOfferEpic: RootEpic = (action$, _, { managed, offerApi, ofIsAuthenticated }) =>
  action$.pipe(
    filter(offerSlice.actions.reportOffer.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    ofIsAuthenticated(),
    managed(
      offerSlice.actions.reportOffer,
      pipe(
        switchMap((action) =>
          from(offerApi.reportOffer(action.payload.offerId, { reason: action.payload.reason }))
        ),
        mergeMap(() =>
          of(
            modalSlice.actions.closeReportOfferModal(),
            logSlice.actions.logSuccess({
              message: translate(translationKeys.confirmation.youHaveSuccessfullyReportedAnOffer),
            })
          )
        )
      ),
      [offerAlreadyReportedError]
    )
  );

const rateOfferEpic$: RootEpic = (action$, _, { managed, offerApi, ofIsAuthenticated }) =>
  action$.pipe(
    filter(offerSlice.actions.rateOffer.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    ofIsAuthenticated(),
    managed(
      offerSlice.actions.rateOffer,
      pipe(
        switchMap((action) =>
          of(action).pipe(
            switchMap((action) =>
              from(
                offerApi.rateOffer(action.payload.offerId, {
                  comment: action.payload.comment,
                  rating: action.payload.rating,
                })
              )
            ),
            mergeMap(() =>
              of(
                ratingSlice.actions.fetchUserRatings({ offerId: action.payload.offerId }),
                modalSlice.actions.closeGiveRatingModal(),
                logSlice.actions.logSuccess({
                  message: translate(translationKeys.confirmation.youHaveSuccessfullyRatedAnOffer),
                })
              )
            )
          )
        )
      )
    )
  );

export const offerEpic$ = combineEpics(
  searchOfferEpic$,
  fetchOffer$,
  requestOfferImmediately$,
  requestOffer$,
  fetchOfferRequests$,
  rejectOfferRequest$,
  acceptOfferRequest$,
  updateOfferStatus$,
  fetchOfferForEdit$,
  createMyOffer$,
  createOfferElitetutorRegistration$,
  updateMyOffer$,
  removeMyOfferEpic$,
  tryRemoveMyOfferEpic$,
  rateOfferEpic$,
  reportOfferEpic
);
